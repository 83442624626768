export const header = (theme) => ({
  marginBottom: '4em',
  '& > h2': {
    textTransform: 'uppercase',
    textAlign: 'center',
    margin: '1em 0',
  },
  '& > p': {
    textAlign: 'center',
    color: theme.secondaryDarker,
  },
  '@media (max-width: 767px)': {
    padding: '0 2em',
  },
});

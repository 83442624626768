/* eslint-disable no-unused-vars */
import React from 'react';
import { NavProductBox } from 'components';
import { products } from 'enums';
import { wrap } from './styles';

const MobileProductsNavDropdown = () => (
  <div css={wrap}>
    {products.map((el) => (
      <div className="product-item" key={el?.id}>
        <NavProductBox data={el} />
      </div>
    ))}
  </div>
);

export default MobileProductsNavDropdown;

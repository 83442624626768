/* eslint-disable no-unused-vars */
import React from 'react';
import Slider from 'react-slick';
import { NavProductBox } from 'components';
import { useTranslations } from '@veraio/strank';
import { products } from 'enums';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { wrap, btnWrap } from './styles';

const SampleNextArrow = (props) => {
  const { className, onClick, currentSlide } = props;

  return (
    currentSlide === 0 && (
      <div css={btnWrap}>
        <div role="button" tabIndex={0} className={className} onClick={onClick} />
      </div>
    )
  );
};

const SamplePrevArrow = (props) => {
  const { className, onClick, currentSlide } = props;

  return (
    currentSlide === 1 && (
      <div css={btnWrap}>
        <div role="button" tabIndex={0} className={className} onClick={onClick} />{' '}
      </div>
    )
  );
};

const ProductsNavDropdown = () => {
  const { getText } = useTranslations();

  const settings = {
    arrows: true,
    dots: false,
    infinite: false,
    slidesToShow: 6,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <div css={wrap}>
      <h4>{getText('oneEcosystemProducts')}</h4>
      <Slider {...settings}>
        {products.map((el) => (
          <NavProductBox data={el} key={el?.id} isSlider />
        ))}
      </Slider>
    </div>
  );
};

export default ProductsNavDropdown;

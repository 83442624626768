export const productBox = (largeText, isSlider) => (theme) => ({
  border: `1px solid ${theme.primary}`,
  cursor: 'pointer',
  margin: isSlider ? 10 : 0,
  '& .product-image-wrap': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2em',
    '& .product-image': {
      maxWidth: '100%',
      height: 'auto',
      maxHeight: 160,
    },
  },
  '& .product-name': {
    borderTop: `1px solid ${theme.primary}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1em',
    color: theme.secondaryDark,

    '& > p': {
      fontSize: '0.9rem',
      textTransform: 'uppercase',
      margin: 0,
      letterSpacing: 1.5,
    },
    '& > i': {
      color: theme.primary,
    },
  },

  '&:hover': {
    border: `1px solid ${theme.secondaryDarker}`,
    '& .product-name': {
      '& > i': {
        color: theme.secondaryDarker,
      },
    },
  },

  '@media (max-width: 1399px)': {
    '& .product-name': {
      '& > p': {
        fontSize: '0.8rem',
      },
      '& > i': {
        fontSize: '0.95rem',
      },
    },
  },

  '@media (max-width: 1199px)': {
    '& .product-name': {
      padding: '0.6em',
      '& > p': {
        fontSize: '0.6rem',
      },
      '& > i': {
        fontSize: '0.9rem',
      },
    },
  },

  '@media (max-width: 991px)': {
    '& .product-name': {
      padding: largeText ? '1em' : '0.5em',
      '& > p': {
        fontSize: largeText ? '0.8rem' : '0.55rem',
      },
      '& > i': {
        fontSize: largeText ? '1rem' : '0.7rem',
      },
    },
  },
  '@media (max-width: 767px)': {
    '& .product-name': {
      padding: '0.8em',
      '& > p': {
        fontSize: '0.9rem',
      },
      '& > i': {
        fontSize: '1.3rem',
      },
    },
  },
});

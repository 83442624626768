import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { Dropdown, showError } from 'components';
import { getCountries } from 'services';
import { wrap } from './styles';

const CountriesSelect = ({ value, onChange, className }) => {
  const { getText } = useTranslations();
  const [countries, setCountries] = useState(null);

  useEffect(() => {
    fetchCountries();
  }, []);

  const fetchCountries = async (page) => {
    const [res, err] = await getCountries({
      page,
      skipTotal: true,
      expand: 'continentId',
      sort: 'continentId',
      perPage: 100,
      fields: 'id,name,expand',
    });
    if (err) return showError(err?.data);

    const formatData = res?.items?.reduce((accumulator, currentValue) => {
      const groupingValue = currentValue?.expand?.continentId?.name;
      const lastElement = accumulator[accumulator.length - 1] ?? {};

      if (lastElement?.expand?.continentId?.name !== groupingValue) accumulator.push({ groupLabel: groupingValue });

      accumulator.push(currentValue);

      return accumulator;
    }, []);

    setCountries(formatData);
  };

  const handleChange = (data) => isFunction(onChange) && onChange(data);

  return (
    <div css={wrap} {...(className && { className })}>
      {countries && (
        <Dropdown
          withSearch
          placeholder={getText('allLocations')}
          className="countries-dropdown"
          displayKey="name"
          uniqueKey="id"
          options={countries}
          value={value}
          onChange={handleChange}
        />
      )}
    </div>
  );
};

CountriesSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

export default CountriesSelect;

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { usePopper } from 'react-popper';
import { BodyOverflow } from 'components';
import { tooltipContainer } from './styles';

const PopperBox = (props) => {
  const {
    children,
    content,
    onClick,
    open,
    className,
    placement = 'auto',
    strategy,
    offset,
    closeOnClick = false,
    zIndex,
    mainContainerProps,
  } = props;

  const [isOpen, setIsOpen] = useState(open);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [offset && { name: 'offset', options: { offset } }].filter(Boolean),
    placement,
    strategy,
  });

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const handleClick = ({ target }) =>
    (!popperElement?.contains(target) || closeOnClick) && onClick && setIsOpen((prev) => !prev);

  const handleBlur = ({ relatedTarget }) => !popperElement?.contains(relatedTarget) && onClick && setIsOpen(false);

  const handleMouseEnter = () => !onClick && setIsOpen(true);

  const handleMouseLeave = () => !onClick && setIsOpen(false);

  return (
    <>
      <div
        role="button"
        tabIndex={0}
        ref={setReferenceElement}
        onClick={handleClick}
        onBlur={handleBlur}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        css={tooltipContainer}
        {...(className && { className })}
        {...mainContainerProps}>
        {isOpen && (
          <BodyOverflow fixed className="tooltip-body-overflow" zIndex={zIndex}>
            <div ref={setPopperElement} style={styles.popper} {...attributes.popper}>
              {content}
            </div>
          </BodyOverflow>
        )}
        {children}
      </div>
    </>
  );
};

PopperBox.propTypes = {
  children: PropTypes.any,
  content: PropTypes.any,
  open: PropTypes.bool,
  onClick: PropTypes.bool,
  className: PropTypes.string,
  placement: PropTypes.string,
  strategy: PropTypes.string,
  offset: PropTypes.array,
  noArrow: PropTypes.bool,
  closeOnClick: PropTypes.bool,
  zIndex: PropTypes.number,
  mainContainerProps: PropTypes.object,
};

export default PopperBox;

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Row, Col, ArrowLink, EventBox, showError } from 'components';
import { getTranslations } from 'utils';
import { getEvents } from 'services';
import { wrap } from './styles';

const LatestEvents = ({ titleKey }) => {
  const { getText, language } = useTranslations();
  const [event, setNews] = useState();

  useEffect(() => {
    language && fetchEvents();
  }, [language?.code]);

  const fetchEvents = async () => {
    const [res, err] = await getEvents({
      perPage: 3,
      sort: '-startDate',
      filter: '(isActive=true)',
      fields: `id,collectionId,image,title,description,createdBy,startDate,endDate,${getTranslations(
        ['title', 'description'],
        language?.code,
      )}`,
      skipTotal: true,
    });
    if (err) return showError(err?.data);
    setNews(res);
  };

  return (
    <div css={wrap}>
      <Row container>
        <Col sm={12}>
          <div className="header">
            <h4 className="title">{getText(titleKey)}</h4>
            <ArrowLink title={getText('allEvents')} linkTo={'/'} />
          </div>
        </Col>
      </Row>
      <Row container>
        {event?.items?.map((el) => (
          <Col md={4} key={el?.id} className="news-box-item">
            <EventBox data={el} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

LatestEvents.propTypes = {
  titleKey: PropTypes.string,
};

export default LatestEvents;

import { useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';

const BottomSheetModal = forwardRef((props, ref) => {
  const { content } = props;
  const [show, setShow] = useState(false);

  useImperativeHandle(ref, () => ({
    open: () => setShow(true),
    close: () => setShow(false),
  }));

  return (
    <BottomSheet
      onDismiss={() => setShow(false)}
      open={show}
      defaultSnap={({ maxHeight }) => maxHeight / 2}
      snapPoints={({ maxHeight }) => [maxHeight / 2]}>
      {content}
    </BottomSheet>
  );
});

BottomSheetModal.propTypes = {
  content: PropTypes.node,
};

export default BottomSheetModal;

export const wrap = {
  padding: '5em 4em 5em 4em',
  '& h4': {
    marginBottom: '2em',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
};

export const btnWrap = (theme) => ({
  '.slick-prev:before': {
    content: "'❮'",
    color: theme.secondaryDarker,
    fontSize: '1.5rem',
    opacity: '0.85',
  },
  '.slick-next:before': {
    content: "'❯'",
    color: theme.secondaryDarker,
    fontSize: '1.5rem',
    opacity: '0.85',
  },
});

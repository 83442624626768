/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Button, EventTypeBadge } from 'components';
import { getImagePath, getTranslationData } from 'utils';
import { wrap, eventImage } from './styles';

const PopperEventBox = ({ data }) => {
  const { getText, language } = useTranslations();

  return (
    <div css={wrap}>
      <div className="p-box-header">
        <div css={eventImage(getImagePath(data))} />
      </div>
      <div className="p-box-body">
        <EventTypeBadge createdBy={data?.createdBy} />
        <h6 className="event-title">{getTranslationData(data, 'title', language?.code)}</h6>
        <p className="event-text">{getTranslationData(data, 'description', language?.code)}</p>
        <Button className="event-button" type="secondary" size="small" linkTo={`/events/${data?.id}`}>
          {getText('viewEvent')}
        </Button>
      </div>
    </div>
  );
};

PopperEventBox.propTypes = {
  data: PropTypes.object,
};

export default PopperEventBox;

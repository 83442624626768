export const termsAndPolicyContainer = {
  padding: '3em 1em',

  '.paper-box': {
    margin: '0 auto',
    maxWidth: 1280,
    width: '100%',

    '.terms-paragraph': {
      fontSize: 15,
      marginBottom: '2.5em',
      paddingLeft: '1.5em',

      li: {
        marginBottom: '1em',
      },

      '.dashed': {
        listStyleType: '-',
      },

      '.nested': {
        paddingLeft: '2.5rem',
      },

      '.disc-list': {
        listStyleType: 'disc',
      },

      '.circle-list': {
        listStyleType: 'circle',
      },
    },

    '.terms-paragraph-title': {
      marginBottom: '1em',
      marginTop: '3.5em',

      '&:first-of-type': {
        marginTop: 0,
      },
    },

    '.terms-paragraph-subtitle': {
      margin: 0,
      marginBottom: '1em',
    },

    '.center': {
      textAlign: 'center',
    },
  },
};

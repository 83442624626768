export const header = {
  padding: '0 4em',
  marginBottom: '4em',
  '&  h2': {
    textTransform: 'uppercase',
    textAlign: 'center',
    margin: '1em 0 0.5em 0',
  },
  '@media (max-width: 991px)': {
    padding: '0 2em',
    marginBottom: '3em',
  },
};

export const body = {
  padding: '0 4em',
  marginBottom: '2em',
  '@media (max-width: 991px)': {
    padding: '0 2em',
    '&  > div ': {
      maxWidth: '100%',
    },
  },
};

export const wrap = (theme) => ({
  marginBottom: '1em',
  border: `1px solid ${theme.primary}`,
  display: 'flex',
  justifyContent: 'space-between',
  height: '4rem',

  '& > .left-side': {
    padding: '0 1em',
    borderRight: `1px solid ${theme.primary}`,
    display: 'flex',
    alignItems: 'center',
  },

  '& > .right-side': {
    padding: '0 1em',
    display: 'flex',
    alignItems: 'center',
    borderLeft: `1px solid ${theme.primary}`,
  },

  '@media (max-width: 767px)': {
    height: '6rem',
    '& > .right-side > div > div': {
      marginBottom: '0.5em',
      '&:last-of-type': {
        marginBottom: 0,
      },
    },
    '& > .right-side > div > div span': {
      fontSize: '0.9rem',
    },
  },
});

/* eslint-disable no-unused-vars */
import React from 'react';
import { Row, Col, Tabs, Tab, ProductsTabsContent } from 'components';
import { useTranslations } from '@veraio/strank';
import { products } from 'enums';
import { wrap } from './styles';

const noPresentedProducts = (el) => el.name.toLowerCase() !== 'one portal' && el.name.toLowerCase() !== 'one forex';

const ProductsTabs = () => {
  const { getText } = useTranslations();
  return (
    <div css={wrap}>
      <Row container>
        <Col sm={12}>
          <div className="header">
            <h3 className="title" data-strank-key="discoverGlobalEducationalProducts">
              {getText('discoverGlobalEducationalProducts')}
            </h3>
          </div>

          <Tabs>
            {products.filter(noPresentedProducts).map((el) => (
              <Tab
                key={el?.id}
                label={el?.name}
                component={() => (
                  <ProductsTabsContent
                    title={el?.name}
                    text={el?.shortTextKey}
                    image={el?.imageDark}
                    upcoming={el?.upcoming}
                  />
                )}
              />
            ))}
          </Tabs>
        </Col>
      </Row>
    </div>
  );
};

export default ProductsTabs;

import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { wrap } from './styles';

const EventTypeBadge = ({ createdBy, className }) => {
  const { getText } = useTranslations();
  const isCorporate = createdBy === 'OneEcosystem';

  return (
    <div css={wrap} {...(className && { className })}>
      <p>{getText(isCorporate ? 'corporateEvent' : 'imaEvent')}</p>
    </div>
  );
};

EventTypeBadge.propTypes = {
  createdBy: PropTypes.string,
  className: PropTypes.string,
};

export default EventTypeBadge;

import { useEffect } from 'react';
import { termsAndPolicyContainer } from './styles';

const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <section css={termsAndPolicyContainer}>
      <div className="paper-box">
        <p className="terms-paragraph">
          Thank you for visiting our website. We would like to warmly welcome you on behalf of our company as an
          Independent Marketing Associate (hereinafter referred to as our <b>&ldquo;IMA&rdquo;</b> or &lsquo;you&rsquo;,
          &lsquo;your&rsquo;, as appropriate), and hope your endeavours as an IMA of our company bring you nothing but
          happiness and success. Please read the Terms and Conditions (hereinafter referred to as T&C) carefully before
          using this website.
        </p>

        <h4 className="terms-paragraph-title">1. INTRODUCTION</h4>
        <ol className="terms-paragraph">
          <li>
            The Company,&nbsp;
            <b>
              ONE ECOSYSTEM WORLD JOINT STOCK COMPANY, SH25-K7.TT1, Starlake Urban Area, West Lake, Xuan La Ward, Tay Ho
              District, Hanoi City, Vietnam
            </b>
            &nbsp;is a registered trademark (hereinafter referred to as&nbsp;
            <b>&ldquo;the Company&rdquo;</b> , or &lsquo;we&rsquo;, &lsquo;us&rsquo;, &lsquo;our&rsquo; and
            &lsquo;ourselves&rsquo;, as appropriate)
          </li>
          <li>
            The present website{' '}
            <a href="http://www.oneecosystem.eu" target="_blank" rel="noreferrer" className="website-link-styles">
              oneecosystem.eu
            </a>{' '}
            (hereinafter referred to as <b>&bdquo;the Website&rdquo;</b>) is provided by the Company as a service,
            subject to the present General Terms and Conditions. The use of the Website and the facilities provided
            acknowledges the acceptance and understanding of the present General Terms and Conditions.
          </li>
          <li>
            For your own benefit and security, please make sure to take your time and read the present T&C as well as
            any other additional documentation and information available to you via our website. A glossary of all terms
            can be found in Schedule A to this T&C.
          </li>
          <li>
            Regarding the distribution of our products and interactions with others, it is crucial to ensure a
            consistently pleasant and safe experience for our clients which is marked by reliability and fair
            cooperation with each other and with the Network Marketing Industry, while maintaining the law and ethical
            principles. We would therefore ask you to read our Ethical rules thoroughly and use these principles as your
            guide while performing your day-to-day activities. Our success as a Company depends significantly on the
            compliance with our ethical principles. The Ethical rules can be found in Schedule B to this T&C.
          </li>
          <li>
            If there is anything in these T&C which you don&rsquo;t understand you should contact us as soon as possible
            or take independent advice.
          </li>
        </ol>
        <h4 className="terms-paragraph-title">2. SCOPE OF THE GENERAL TERMS AND CONDITIONS.</h4>
        <ol className="terms-paragraph">
          <li>
            By accessing, viewing and/or using the website, you automatically agree that you have read, understood and
            agreed to be bound by these Terms and Conditions and that you will comply with all applicable legal and
            other regulations.
          </li>
          <li>
            By using the website:{' '}
            <a href="http://www.oneecosystem.eu" target="_blank" rel="noreferrer" className="website-link-styles">
              www.oneecosystem.eu
            </a>
            , you agree with our Data Protection Notice.
          </li>
          <li>If you do not accept the present Terms and Conditions, please do not use the website.</li>
          <li>
            Please note that there are other documents (Related Documents) and information available on our website
            which form part of the General Terms and Conditions and provide other details about us and your activities
            carried out with us.
          </li>
          <li>
            The General Terms and Conditions contain all terms and conditions that govern the relationship between us
            and your activities as an IMA. These T&C override any other previous agreements, arrangements and expressed
            or implied statements made by us. Any actions, inactions or statements (oral or written) made by you or us,
            including any of our employees with whom you may have interacted, shall not amend or take priority over the
            present T&C.
          </li>
        </ol>

        <h4 className="terms-paragraph-title">3. SUBJECT OF THE GENERAL TERMS AND CONDITIONS.</h4>
        <ol className="terms-paragraph">
          <li>
            The present General Terms and Conditions are an integral part of every sales partnership agreement between
            the Company, represented by its Managing Director(s), and the independent, self-employed IMA.
          </li>
          <li>
            Users understand and consent to using the website in good faith and abstain from any malicious actions that
            may affect the accessibility of the content to third parties or the use of the Website in general.
          </li>
          <li>
            The Company is a Network Marketing Company, which exclusively provides to its members educational courses
            and related to them additional products and services, including products and services provided to the
            Company by third parties.
          </li>
          <ol className="nested disc-list">
            <li>
              The educational modules are made available exclusively to the members of the Company. A member can be a
              person over the age of 18. Every existing IMA can currently invite a new person to self-register. After
              the account has been created, the new member will receive a confirmation email at the email address from
              which he/she has registered. After the email confirmation every new member is entitled to access the free
              Company Rookie Account.
            </li>
            <li>
              The Rookie Account provides access to the website but does not require the purchase of products from the
              Company&apos;s portfolio. Through the Rookie Profile, the IMA can make a network and distribute the
              Company&apos;s products and services. The Rookie profile allows the IMA to access the Dashboard in their
              profile (&lsquo;my profile&rsquo;) and the Wallets (&lsquo;Wallet&rsquo;), as well as the catalogue with
              educational modules that can be purchased.
            </li>
            <li>
              The Profile contains all personal information records for each IMA such as: Name, Date and Place of Birth,
              Address, Password, Identification Information and more. Each IMA can have only one personal profile. The
              IMA can have more than one account in his/her profile.
            </li>
            <li>
              The profile is not connected to the binary network structure that the Company uses in any way other than
              the accounts. After registering and creating a new profile, the new IMA will automatically receive his/her
              first account with all information about the Wallet and the Network in his/her account, as well as his/her
              position in the binary Network structure.
            </li>
          </ol>
        </ol>

        <h4 className="terms-paragraph-title">4. ELIGIBILITY / GENERAL PREREQUISITES.</h4>
        <ol className="terms-paragraph">
          <li>
            Services shall only be provided to individuals under the condition that they are at least 18 years old and
            are not present or former Company&apos;s employees or their respective spouse/lineal ascendants or
            descendants/affinity and collateral relatives up to forth degree.
          </li>
          <li>
            All online forms and other Company&apos;s official documents, published on the Company&apos;s website,
            constitute an integral part of the present General Terms and Conditions.
          </li>
          <li>
            All changes in the IMAs&apos; personal data shall be made immediately in the designated section in the
            Dashboard in their Profile.
          </li>
          <li>
            The Company reserves the right to refuse IMAs` registration at its discretion, without any need to provide a
            cause. The Company explicitly states that at this time no applications from potential partners in the USA
            and their territories of operation shall be accepted.
          </li>
          <li>
            In the event of a breach of any obligations the Company shall terminate the conditions of services under the
            present General Terms and Conditions without notice and, if deemed necessary, to demand the return of any
            bonuses that have already been paid out. In addition, the Company expressly reserves the right to pursue
            additional costs for damages in such cases.
          </li>
          <li>
            The IMA hereby represents and warrants that they have not been coerced, or otherwise persuaded to enter into
            the T&C, nor have they entered into our cooperation based on any representation other than what is included
            herein.
          </li>
          <li>
            IMA declare that their citizenship/residence/address of management and/or their representative&apos;s
            citizenship/residence legislation allows IMA to enter into this Agreement and into a relationship with us.
            IMA agree that if any false declaration under the sentence above incurs any losses, including expected or
            unexpected, now and in the future, the Company shall not be held liable.
          </li>
        </ol>

        <h4 className="terms-paragraph-title">5. PRODUCTS - TYPES, QUALITY AND USE.</h4>
        <ol className="terms-paragraph">
          <li>
            The Company is a Network Marketing company, which exclusively provides to its members, educational courses
            and related to them additional products and services, such as virtual tokens.
          </li>
          <ol className="nested disc-list">
            <li>
              Products, intended for the Company&apos;s business activities, are educational courses, covering subjects
              from different categories provided by the Company, business partners or affiliated companies, including
              other products that the Company may market at any time (&quot;Products&quot;).
            </li>
            <li>
              Detailed information about the Products, including name, type, warranty (if any), selling price and
              relevant bonus points, is presented in the Catalogue of Educational Modules in the Dashboard.
            </li>
          </ol>
          <li>
            Each educational module purchased will provide the IMAs with tokens, giving them access to mining pools.
          </li>
          <li>
            A certain number of promotional tokens will be assigned to the member once they have redeemed the purchased
            educational course, and the rest will be received after they complete the training exam in OneAcademy
            platform.
          </li>
          <li>
            The promotional tokens generated give access to the mining pools of ONE, however there is no guarantee as to
            when or how many ONEs will be received. The total number of ONEs mined can be changed in accordance with the
            Company&apos;s strategy.
          </li>
          <li>
            The Rookie is free educational module which includes information about our integrated virtual promotional
            offers and how they can be used for personal development and advancement. The Rookie module allows members
            to generate promotional tokens and bonuses from the Compensation Plan. The members and the Company are
            solely responsible for any additional actions related to the management of the tokens which have been
            assigned
          </li>
        </ol>

        <h4 className="terms-paragraph-title">6. PAYMENT METHODS.</h4>
        <ol className="terms-paragraph">
          <li>The Company accepts the following payment methods for the purchase of goods:</li>
          <ol className="nested disc-list">
            <li>Cash wallet</li>
            <li>ONE wallet</li>
          </ol>
          <li>The Company warns that the above stated list of payment methods may be modified occasionally.</li>
        </ol>

        <h4 className="terms-paragraph-title">7. REMUNERATION / PAYMENT TERMS / BONUS PAYMENT METHODS.</h4>
        <ol className="terms-paragraph">
          <li>
            The IMA&apos;s commissions, bonuses and economic benefits which result from sales and sponsorship activities
            and/or from the use of the system&apos;s network are outlined in the Company&apos;s Compensation Plan.
          </li>
          <li>
            Commissions, bonuses and other economic benefits are paid to the IMA by a transfer of funds to their cash
            and/or token wallet, and are carried out in accordance with the conditions described in this T&C.
          </li>
          <li>The Company&apos;s remuneration plan includes four types of bonuses:</li>
          <ol className="nested disc-list">
            <li>
              <i>Direct bonus</i> - For this bonus the IMA receives 10% of the total number of Business Points (BP)
              accumulated in his/her account from the registered accounts through his/her invitation.
            </li>
            <li>
              <i>Network bonus</i> - For this bonus the IMA receives 10% of the total business points accumulated
              because his/her network structure contains fewer business points (BP).
            </li>
            <li>
              <i>Matching bonus</i> - For this bonus the IMA is entitled to a percentage of the Network Bonus for each
              registered account through his/her invitation and for the IMAs invited by him/her up to the 4th
              generation. Bonus rates are as follows:
            </li>
            <ol className="nested circle-list">
              <li>
                For the first generation, members get 10% of the network bonus received by the IMAs, who the member
                invited directly.
              </li>
              <li>
                For the second generation, members receive 10% of the network bonus received by IMAs, who were invited
                directly by the member&apos;s first generation.
              </li>
              <li>
                For the third generation, members receive 20% of the network bonus received by IMAs, who were invited
                directly by the member&apos;s second generation.
              </li>
              <li>
                For the fourth generation, members receive 25% of the network bonus received by IMAs, who were invited
                directly by the member&apos;s third generation.
              </li>
            </ol>
            <li>
              <i>Start-up bonus</i> - During the first 30 days after using the first educational module, the IMA
              receives 10% of the total number of business points in excess of 5500 points accumulated in his/her
              account from the accounts that have been registered trough his/her invitation.
            </li>
          </ol>
          <li>
            The Direct bonus, Network bonus and Matching bonus, which were received by the IMA, are denominated in
            Euros, divided into two parts, 60% are paid to their Cash Wallet and 40% to their Token Wallet.
          </li>
          <li>Startup bonuses are paid entirely into the IMA&apos;s Token Wallet.</li>
          <li>
            All accounts have a limit on the amount of money (Bonus cap), which is allowed from the compensation plan,
            based on the price of the educational module.
          </li>
          <li>The company introduces the following restrictions:</li>
          <ol className="nested disc-list">
            <li>Non-deployed accounts will not receive BVs</li>
            <li>All available BVs in non-deployed accounts will be reset</li>
            <li>
              After (if) the IMA deploys his/her account, he/she starts receiving (collecting) BVs in it starting from
              zero – removed BVs will not be reinstated.
            </li>
          </ol>
          <li>
            The Company reserves the right to require from the IMAs to prove his/her identity before any bonuses have
            been paid or any services have been delivered for the first time. The Company may at its discretion require
            that the IMA proves his/her identity within 5 working days with a copy of their personal ID or passport, an
            excerpt from the Commercial Register, a VAT certificate, or some alternative TAX ID, potentially in
            conjunction with a recent electricity, gas water or other utility statement. The IMA is responsible for
            accurate and up to date personal information and shall use reasonable means to ensure this.
          </li>
          <li>
            The IMA shall provide to the Company their tax ID and a copy of the confirmation letter from the responsible
            tax office immediately upon opting to pay VAT on their business activities or when their business volume
            exceeds the thresholds set for small business exemptions.
          </li>
          <li>
            The Company may assert its right of retention in accordance with the law. In addition, the Company may
            exercise its retention right by withholding the payment of bonuses and withdrawal applications if any of the
            documents required by law, (such as the VAT identification number for legal entities, as requested and
            issued; and business registration etc.) have not been provided upon request before the first payment is due.
            If the Company exercises its right to withhold bonus payments, the parties hereby agree that the IMA shall
            not demand interest on the amount payable.
          </li>
          <li>
            If the IMA loses his/her qualified IMA status, he/she forfeits all claims to bonuses for the time period
            after which said status has been lost. It is of course possible for the IMA to regain the respective
            qualification in the future again, but the previous bonus claims shall not be reactivated. If the IMA loses
            his/her qualifying IMA status, he/she shall lose all rights to claim rewards for the time period he/she had
            lost that status.
          </li>
          <li>
            The IMAs shall not assign or pledge claims, arising from the IMAs agreements, unless ordered by a court of
            law. The IMA Agreement may not be encumbered with the rights of third parties, unless ordered by a court of
            law.
          </li>
          <li>
            The Company shall be notified in writing within five (5) working days (starting from the date of payment) of
            any incorrect bonuses or other payments. After this time, the bonuses or other payments shall be deemed as
            accepted and approved.
          </li>
          <li>
            The IMA is responsible for providing accurate input in payment instructions, especially the
            beneficiary&apos;s crypto wallet address. By making a transfer to the specified account, the Company has
            fulfilled its obligations to the IMA, regardless of whether the IMA has provided the right account
            information or not.
          </li>
          <li>
            The IMA is responsible for providing us with correct and accurate information at all times. We can rely on
            the information IMA have provided us with, both during on-boarding and throughout our relationship, unless
            we have reason to believe that the information IMA have provided us with is inaccurate. If any of the
            information IMA have provided us with changes, IMA need to notify us in writing without undue delay.
          </li>
          <li>
            We reserve the right to limit, block access to our Services, Website and/or Backoffice and/or to terminate
            and/or close IMA account/s with us, if IMA do not provide us with information we require for verification of
            their identity or if any such information provided to us appears to be untrue, inaccurate, incomplete and/or
            incorrect.
          </li>
        </ol>

        <h4 className="terms-paragraph-title">8. RIGHTS AND OBLIGATIONS OF IMAs.</h4>
        <ol className="terms-paragraph">
          <li>Status of the IMA as partners</li>
          <ol className="nested disc-list">
            <li>
              In this business relationship, the IMAs are acting as independent, autonomous partners. They are not and
              can’t be treated as employees, sales representatives, or brokers of the Company. There are no requirements
              as to the revenues, sales, minimum deliveries, etc. The IMAs are not subject to any instructions from the
              Company, other than their contractual obligations, and they bear the full entrepreneurial risks of their
              business activity, including the obligation to pay all their business costs and the wages to their
              employees, if any. The IMAs shall set up and operate their businesses in accordance with the sound
              business principles, including the operation of their own offices or other workplaces, which are
              appropriate for the conduct of business.
            </li>
            <li>
              As independent partners, the IMAs are personally responsible for compliance with the relevant legal
              conditions, including tax and social insurance requirements (e.g. registration for a VAT ID number,
              registration of their employees in the social insurance system, obtaining business licenses if required).
              In this regard, the IMAs shall comply with all tax and other payment obligations (such as customs duties
              or import taxes) as stipulated by the jurisdiction of their registered office, with respect to the revenue
              they produce from their operations. The Company reserves the right to deduct the respective amount for
              taxes and fees from the bonus and/or demand reimbursement for damages or expenses incurred by due to the
              IMA’s breach of the present stipulations, as long as the IMA is responsible for the same. The Company is
              not being paid any social insurance contributions or other taxes, related to deliveries for the IMA. The
              IMA is not authorised to make any statements or comments on behalf of the Company.
            </li>
            <li>
              The Company would like to point out expressly that the IMA&apos;s success depends entirely on their
              personal commitment, abilities, and effort. Furthermore, the Company does not guarantee or declare any
              specific level of remuneration or other income from the mere participation in the Network and also does
              not provide a package of services that would result in such guaranteed remuneration. <br />
              <br />
              <i>
                In addition to the other rights and obligations specified in these General Terms and Conditions and the
                relevant documents, the IMAs have the following rights and obligations:
              </i>
              <br />
              <br />
            </li>
          </ol>
          <li>The IMA has the following rights:</li>
          <ol className="nested disc-list">
            <li>
              The right to sell the Company&apos;s legal products and build a marketing network based on the conditions
              of law and the present General Terms and Conditions.
            </li>
            <li>
              The IMAs may use, produce or disseminate their own sales documents, product brochures, promotion videos,
              or other self-generated online or offline media and advertisement materials only in accordance with the
              contractual requirements, branding book and current legislation. In addition, they are allowed to only
              work with the official Company’s advertising materials. The same also applies to advertising Company’s
              system and products on his/her own or external websites. In the event that the IMA promotes the Company’s
              system and products in other online media such as social networks (e.g. Facebook), blogs or chat rooms,
              he/she shall always only use official and approved advertising messages. Furthermore, when using other
              online media, the IMA shall explicitly indicate that it is not an official Company advertisement or online
              presence of the Company, but independently developed by the IMAs advertisement of their activity. Unless
              otherwise specified in the present T&C, in the IMA Agreement or in another legally binding the IMA and the
              Company document, the IMA is not allowed to promote, organize or in any other way participate in
              activities involving the acceptance of ONE currency by merchants as a mean of payment without prior
              written consent of the Company and provided that all the applicable Company’s requirements, policies and
              procedures are met, including regarding CDD (Customer due diligence).
            </li>
            <li>
              The Company’s system and products may be presented face-to- face at home-based parties or other events, at
              online parties, webinars or other online presentations, only in compliance with the Company’s
              advertisement policies and in compliance with the relevant Copyright legislation. The IMAs agree and
              accept that it is expressly indicated hereto, that the Company is not responsible and cannot be held
              liable for any advertising materials dispersed by the IMAs and any established violations of the Ethical
              Rules, the present General Terms and Conditions and all other legally related official documents, shall be
              subject of sanctions, including immediate suspension of the IMA’s profile for the period of 1 to 3 months
              and/or imposition of a fine up to €500.
            </li>
            <li>
              The IMAs have the right to receive commissions, bonuses and other economic benefits from the
              Company&apos;s marketing and sales activities as well as from the subordinate affiliate system sponsored
              and/or recruited by the IMAs after being approved by the Company, which has been agreed to in the
              Compensation Plan. The IMAs must meet the conditions set forth in the Compensation Plan and not violate
              the policies, which have been set forth in the General Terms and Conditions and the related documents. The
              IMAs will receive a bonus for his/her marketing activities. This activity does not require the IMAs to
              incur any costs, receive a minimum quantity of product, or recruit new IMAs. The only condition is to have
              registered.
            </li>
            <li>
              In addition, there is a possibility, but not an obligation to recruit other IMAa. Upon obtaining the
              required qualification, the IMAs receive a bonus for the performed sales and for the support they have
              provided to the IMAs they have recruited. A bonus is expressly not paid for the mere recruitment of new
              IMAs, but for the value of the sales. The bonus and the manner in which it will be paid will be based on
              these T&C and the compensation plan in force at that time.
            </li>
            <li>
              The IMAs are provided free of charge online support office for their operations; This office shall provide
              a comprehensive and up-to-date view of the sales, bonuses and developments in the number of their
              customers and junior members. In addition, IMAs may but are not required to purchase various services,
              ongoing training modules, etc. for whose use specific Terms and Conditions may be required and applicable.
            </li>
          </ol>
          <li>IMA’s obligations.</li>
          <ol className="nested disc-list">
            <li>
              All IMAs shall prove that they are at least 18 years old, have full capacity for civil acts, meet the
              conditions to participate in the Network Marketing Business activities and are not prohibited from
              participating in basic Network Marketing activities.
            </li>
            <li>
              The IMAs shall meet and fully comply with the Terms and Conditions when engaging in Network Marketing
              activities with the Company.
            </li>
            <li>
              By submitting an online application to the Company, the Independent Marketing Associates (IMA) declares
              that he/she has read and understood the Compensation plan and accepts these documents as an integral part
              of the General Terms and Conditions.
            </li>
            <li>
              The IMAs are obligated to protect their personal passwords and e-mails from third parties. The Company
              shall not take responsibility for actions undertaken by a person other than the IMA using his/her email
              and password. If the IMAs willingly provide their user ID and/or password to an unauthorised under the
              Company’s T&C person/party the relevant profiles/accounts will be suspended and/or terminated.
            </li>
            <li>Each IMA can own only one personal profile.</li>
            <li>
              The Company takes no responsibility for multiple identities behind a single profile registration. The
              Company recognizes only one profile per one approved KYC, meaning a single profile represents only one
              physical owner.
            </li>
            <li>
              The IMAs are responsible for providing complete and truthful information when introducing the
              Company&apos;s network business as well as the present General Terms and Conditions, compensation plan and
              other official documents of the Company. These documents include future amendments and supplements, which
              have been registered and approved by the Company and/or relevant authorities.
            </li>
            <li>
              The IMAs are prohibited from harming the interests of the Company, other partners of the Company,
              affiliated companies of the Company or other third parties in the course of their operations. They are
              prohibited from violating the clauses of other relevant laws.
            </li>
            <li>
              The IMAs are not authorised to make false or misleading statements about the Company&apos;s products or
              the Network. If such practices are found, the Company will freeze the profile of IMA for a period of at
              least 3 months.
            </li>
            <li>
              The IMAs are required to identify themselves as Independent Marketing Associates in all of their business
              dealings. As a rule, all websites, stationery, business cards, car labels, advertisements, promotional
              materials etc. shall include the words Independent Marketing Associates of Company. The IMAs are also
              prohibited from applying for or taking out loans, incurring expenses, making commitments, opening bank
              accounts or entering into any other contracts on behalf of, in the interest of or in the name of the
              Company.
            </li>
            <li>
              All travel costs, expenses, office expenses, telephone charges and other expenses for advertising
              materials are the IMA’s responsibilities.
            </li>
            <li>
              In the course of their business activities, the IMAs are not authorised to make negative, disparaging or
              otherwise unlawful comments or assessments about competing with other third-party companies, brands, logos
              or other trademarks.
            </li>
            <li>
              All presentations, advertising materials, training and film materials etc. (including photographs) used in
              and for the purposes of the Company are protected by copyright. The IMAs shall not copy, distribute,
              disclose, or otherwise modify the content either as a whole or partly in any way that goes beyond the
              usage already granted contractually without the written consent of the official owners of those
              trademarks.
            </li>
            <li>
              The use (or modification) of the Network and other registered labels, registered trademarks, product
              names, titles of works or trade names beyond the scope of the advertising materials and other official
              documents, that have already been provided, requires the written consent of the Company, which it shall
              grant or withhold solely at its discretion. The IMAs are further prohibited from registering their own
              trademarks, titles, websites or other intellectual property rights that contain the Company’s logo,
              trademark, product names, titles, or trade names of the Company in any other country. The before mentioned
              prohibition applies to both identical and similar signs and the use of the before mentioned characters in
              subdomains or other subcategories of the URL. The repackaging and relabelling of products are also
              prohibited. The Company’s logo shall not be combined with other brands, symbols or logos.
            </li>
            <li>
              The IMAs are not allowed to respond to press enquiries concerning the Company, the Network system, their
              services, marketing plan, or any other matter related to the Company, its activity and other services. The
              IMAs are obliged to immediately forward all press enquiries to the Company. In addition, the IMAs shall
              only make public statements (such as on television, radio or online forums) concerning the Company, its
              range of products, and the sales system after obtaining the written consent of the Company. At this time,
              it is hereby clearly stated that any media or other public statements of IMAs are not and shall not be
              considered as official statements about the Network system or the Company’s products or services.
            </li>
            <li>
              The IMAs will notify the Company of the location, time and content of promotional events, which are
              designed to appeal to the general public in advance of issuing invitations to such events. The Company
              shall at its sole discretion require changes or even the cancellation of such events.
            </li>
            <li>
              Inquiries or complaints of any kind concerning the goods, services, or the compensation system shall be
              forwarded immediately to the Company.
            </li>
            <li>
              Re-selling of gift codes at a discount price is strictly prohibited and against the Company’s policy.
              Should this policy be violated, your money will not be returned.
            </li>
            <li>
              The IMAs shall only sell Company&apos;s system and services or recruit new IMAs in those states and
              countries, which are officially authorised by the Company. It is always prohibited for IMAs to distribute
              or sell their own sales and/or marketing materials to other IMAs.
            </li>
            <li>
              The IMAs shall not disclose any information about their income or the earning opportunities, provided by
              the participation in the Network, in any of the promotional materials. The IMAs are instead expressly
              required to inform potential partnership applicants that only very few partners can achieve higher incomes
              with the network activities and that such incomes are only possible through very intensive, continuous
              effort.
            </li>
            <li>
              The IMAs shall only use these General Terms and Conditions provided by the Company when introducing or
              sponsoring a new member and shall comply with all procedures set forth by the Company in the General Terms
              and Conditions and other related documents.
            </li>
            <li>
              The IMAs shall not require customers or sponsors of a marketing network to pay fees in order to become
              members and to participate in the Network.
            </li>
            <li>
              The IMAs shall not provide false information about the benefits of participating in the Marketing Network,
              the nature and usage of the Company&apos;s products and activities.
            </li>
            <li>
              If the IMAs violate the conditions of these General Terms and Conditions and causes damage to customers or
              other members, the IMAs shall be responsible before the law and third parties.
            </li>
            <li>
              The IMA is an individual, who signs an independent business contract, and not an employee, a
              representative, a founding member or a shareholder of the Company. The IMA cannot create a debt, expense
              or obligation, open an account or record a document on behalf of the Company.
            </li>
            <li>
              The IMAs only select and control the methods and means, which are used to conduct their business in
              compliance with the conditions of law and these General Terms and Conditions.
            </li>
            <li>
              In addition, personal income tax arising from commissions, Company bonuses and economic benefits from
              network marketing activities will be deducted and paid to the State budget and the IMAs shall take full
              responsibility for them.
            </li>
            <li>
              The IMAs are not permitted from transferring the rights or obligations under these General Terms and
              Conditions without the prior written consent of the Company. Every intention to transfer or assign the
              Agreement without the written consent of the Company is a reason for the Company to terminate the
              Agreement. When the Company conducts procedures to investigate violations (if the IMA has committed
              violations), the IMAs shall directly respond and explain and/or send a written response to the
              Company&apos;s representative. The IMAs cannot delegate responses on this matter to others.
            </li>
            <li>
              It is the IMA&apos;s responsibility to give the Company complete, accurate and truthful information,
              related to the Agreement. If the Company discovers (before or after signing the Agreement) that the IMA
              has provided inaccurate information and/or violated the Terms and Conditions, the Company has the right to
              terminate the Agreement with that IMA in accordance with the conditions of law. The IMAs are responsible
              for providing complete, quick and accurate personal information along with all documents required by the
              Company to provide to the relevant state agencies in accordance with the law.
            </li>
            <li>
              The IMAs shall notify the Company immediately and truthfully of any violations of the General Terms and
              Conditions and the Ethical Rules or of any other conditions of the Company. The IMAs are responsible for
              protecting trade secrets, confidential information and other materials owned or used exclusively by the
              Company. This condition will take effect 20 years after the termination of this Agreement.
            </li>
            <li>
              The IMA is allowed to acquire goods for his/her personal use or that of his/her family members or for
              further resale. In addition, the IMA is prohibited from sending unsolicited (spam) advertising emails,
              faxes or SMS messages.
            </li>
            <li>
              The services shall not be offered at auctions, public or private online flea markets, swap networks,
              online department stores, online markets such as eBay, Amazon or comparable venues. Advertising in
              electronic media and mass media is only conditionally allowed. The IMAs shall advertise the Network system
              and products and services on TV, cable TV, radio, newspapers, email or other forms of electronic media or
              mass media only with the Company’s prior written consent. Said consent may be withheld by the Company at
              any time and at its sole discretion. The use of premium rate telephone numbers to market Company’s system
              and products is not permitted.
            </li>
            <li>
              The Company reserves the right to change the Compensation Plan at any time. The Company will notify about
              general amendments within a reasonable period of time. The IMAs have the right to object to the amendment.
              In case of an objection the IMAs are entitled to terminate the IMA Agreement at the date of entry into
              force of the amendment. In case they do not terminate the IMA Agreement within two weeks after the entry
              into force of the amendment, the IMAs expressly accept the modification.
            </li>
            <li>
              The IMAs grant the Company the right to freely collect and use visual and/or audio materials containing
              IMAs images, voice recordings, statements and quotations within the scope of their functions as an IMA.
              The IMAs reserve the right to revoke the above consent. In case of revocation, the Company shall stop
              using the said materials within one month.
            </li>
            <li>
              The abuse and misuse of emails is a serious violation. The Company will not tolerate SPAM. Our Anti-Spam
              policy can be found in Schedule C to this T&C.
            </li>
          </ol>
        </ol>
        <h4 className="terms-paragraph-title">9. RIGHTS AND OBLIGATIONS OF THE COMPANY.</h4>
        <p className="terms-paragraph">
          In addition to other rights and obligations set forth in these General Terms and Conditions and Related
          Documents, the Company has the following rights and obligations:
        </p>
        <ol className="terms-paragraph">
          <li>The Company has the following rights:</li>
          <ol className="nested disc-list">
            <li>
              The Company has the right to monitor the IMA to ensure that he/she complies with these General Terms and
              Conditions, the Ethical Rules and the Compensation Plan. The Company has the right to request from the IMA
              compensation for damages and is allowed to take disciplinary actions in case of violation.
            </li>
            <li>
              The Company and the members of the Board of Directors, management, shareholders and employees of the
              Company (collectively referred to as &quot;related interests&quot;) are not responsible for the damages or
              indirect harm caused by the IMA&apos;s failure to comply with these General Terms and Conditions or the
              Ethical Rules.
            </li>
            <li>
              The Company is not responsible for having multiple identities behind a single profile registration. The
              Company only recognizes a single profile for a registration and KYC approval, which means a single profile
              represents a single physical owner.
            </li>
            <li>
              The Company has the right to deduct the IMA&apos;s personal income tax arising from business activities
              through the network marketing method and transfer it to the state budget in accordance with the law before
              paying commissions, bonuses or other business benefits.
            </li>
          </ol>
          <li>The Company has the following obligations:</li>
          <ol className="nested disc-list">
            <li>
              The Company has the obligation to develop and publish documents, which are related to the Agreement and
              Related Documents at the Company&apos;s main offices, branches, representative offices, business locations
              and Company website.
            </li>
            <li>
              The Company has the obligation to ensure the quality and legality of products sold through network
              marketing methods for the IMA.
            </li>
            <li>
              Liability for damages other than threat to the life, the body or to the health, or caused by the wilful or
              negligent acts of the Company, its employees or agents, is limited to the average amounts specified in
              these contracts as well as any damages that would have been typically discovered at the time the General
              Terms and Conditions were signed. This clause also applies for indirect damages, in particular loss of
              profits.
            </li>
            <li>
              The Company is not responsible for any damages arising from the loss of data on the server unless such
              loss is due to the negligent or intentional acts of the Company, its employees or agents. The Company
              treats any content, kept on servers by the IMAs, as third-party content in accordance with all data
              protection laws and regulations in force. The Company is not responsible for any false data and/or
              information submitted by the IMA in his/her application.
            </li>
            <li>
              Additionally, the Company is not responsible for statements made by Independent Marketing Agents (IMAs)
              regarding earnings, team building or other matters. The Company shall not tolerate unprofessional
              behaviour that may affect the Company&apos;s business reputation. If the IMA encounters unprofessional
              conduct, he/she must immediately notify the Company so that appropriate timely action shall be taken
              against that individual.
            </li>
          </ol>
        </ol>

        <h4 className="terms-paragraph-title">10. NOTICE CONCERNING THE VOLUNTARY RIGHT OF REVOCATION. TERMINATION.</h4>
        <ol className="terms-paragraph">
          <li>
            The IMA has the right to voluntarily terminate his/her business relationship with the Company and terminate
            the IMA Agreement within two weeks from the date of application in accordance with the present General Terms
            and Conditions.
          </li>
          <li>
            The IMA can revoke his/her IMA Agreement by providing written notice (by email) within two weeks without the
            need to provide a cause. The two-week period begins once the IMA submits his/her agreement. The deadline is
            considered met if the notice has been sent by the deadlines, as evidenced by the date of the email. <br />{' '}
            The revocation must be sent to the Support email (
            <a href="http://support@oneecosystem.eu" target="_blank" rel="noreferrer">
              support@oneecosystem.eu
            </a>
            ) or to the Compliance ({' '}
            <a href="http://compliance@oneecosystem.eu" target="_blank" rel="noreferrer">
              compliance@oneecosystem.eu
            </a>
            ).
          </li>
          <li>
            If the IMA terminates his/her business relations with the Company and the IMA Agreement is cancelled, any
            benefits, payments, and/or services received by either party must be returned according to the refund and
            cancellation rules stated in these T&C to the other, together with any benefits derived from the same. At
            this time, we expressly indicate that services are considered to have been used once the IMA has accessed
            the services electronically. If the IMA cannot return the services received either in full or partly or can
            only return them in a condition worse than the one in which they were received, the IMA must reimburse for
            the loss in value. All payment refunds must be made within 30 days. For the IMA, the period begins when
            he/she sends his/her revocation notice, and for the Company upon receipt of the same.
          </li>
          <li>
            The IMA registers in the Network as a customer and not as a consumer and therefore does not have the
            statutory right to revoke the IMA Agreement. Nevertheless, the IMAs are granted on a voluntarily basis the
            right to terminate his/her business relations with the Company and withdraw the IMA Agreement within two
            weeks, from the date the application has been submitted, according to the present General Terms and
            Conditions.
          </li>
          <li>
            The IMA may re-register with the Network, provided that said cancelation was more than 12 months ago and
            that the IMA has not performed any activities for the Network in the meantime.
          </li>
          <li>
            The Company may change or terminate the current T&C at any time and for whatever reason by providing the IMA
            with a minimum of seven (7) days’ notice. Where the Company decide to terminate the T&C, we will specify the
            termination date.
          </li>
          <li>The Company shall terminate cooperation with IMA, with immediate effect, in the event of:</li>
          <ol className="nested disc-list">
            <li>a breach of any part of the T&C by IMA</li>
            <li>
              violation of any of our policies or procedures or if you fail to comply with, or perform any obligation
              under our applicable rules or policies
            </li>
            <li>in case IMA cannot or refuse to provide us with required documents or information</li>
            <li>we believe IMA cannot pass our Know-Your-Customer procedure</li>
            <li>IMA abuse (or we have reasonable grounds to believe you abuse) our systems, website or backoffice</li>
            <li>
              we have reasonable grounds to believe that IMA have not acted in good faith and perform any abusing
              behaviour
            </li>
            <li>IMA involves us in any type of fraud or illegal activities</li>
            <li>
              we establish that IMA are a citizen or resident of a country not on the list of eligible countries in
              accordance with our money laundering and other internal rules
            </li>
            <li>
              a breach of any applicable law by IMA, including, but not limited to the Anti-Money Laundering and
              Countering the Financing of Terrorism legislation
            </li>
            <li>
              The Company has determined that IMA is participating in / working for a competitive MLM project and
              promoting competitive products and/or services.
            </li>
            <li>in other cases, described in the current T&C.</li>
          </ol>
          <li>
            The termination of the T&C shall not imply that any of IMA responsibilities cease to exist. IMA will still
            be liable to pay us, and/or we will have the right to deduct immediately:
          </li>
          <ol className="nested disc-list">
            <li>any amount due to us</li>
            <li>any expenses incurred by us as a result of the termination of the Agreement</li>
            <li>any damage arising after an arrangement or settlement</li>
          </ol>
        </ol>

        <h4 className="terms-paragraph-title">
          11. ADMINISTRATIVE SUPPORT AND HANDLING CHARGES / DELIVERY OF PRODUCTS.
        </h4>
        <ol className="terms-paragraph">
          <li>
            By applying and being approved, the IMAs obtain the right to use the back office provided to them. This is a
            simple, non-transferable right to use the specific web-based back office. The IMAs neither have the right to
            modify, edit, or otherwise reorganize the back office nor may they grant sub licenses to the same. The back
            office shall provide an up-to-date and comprehensive overview of his/her sales, bonuses, and growth in
            clients and downline figures. In addition, the IMAs may, but are not required to, purchase various services
            like continuing education modules, etc., for which the acceptance of additional specific for the services
            Terms and Conditions of use may be required and applicable.
          </li>
          <li>
            The IMA agrees that the ownership over the purchased educational course/module, together with all additional
            accessories and/or supplement products, transfers to him/her upon the activation of the educational modules
            available for use at his/her personal back-office space provided. By logging into his/her Network
            back-office space and/or accessing the product storage of his or her back office, the IMA hereby agrees that
            he/she shall be considered to have broken the seal of the products (started using the product).
            Consequently, the risk of loss and title for such items pass to the IMA at the time they become available
            for him or her.
          </li>
          <li>
            The IMA hereby agrees that he/she understands and accepts that the Company is not responsible for any
            delivery delay or loss of product or subsequent losses resulting from such delays if not caused with
            negligence by the Company.
          </li>
          <li>
            For any product that is to be provided to the IMA in an electronic format, he/she agrees that delivery of
            such products shall be deemed to have occurred at the time the purchased product is available for use and/or
            view at the Company’s website.
          </li>
          <li>
            Activation codes for the Company’s Academy Educational Modules are technical codes that can be used to a
            limited extent only due to the constant technical development. For this reason, during the purchase process
            of the Company’s Educational Module activation code, the IMAs can redeem the activation code for making use
            of the Educational Modules and the contained services therein only within six months. The period for
            redeeming the code starts at the day following the transmission of the activation code. By accepting the
            present T&C and utilizing the products purchased, the IMAs declare to agree with the fact and recognize it
            as being a contract component, that the activation code for the Company’s Educational Modules which IMAs
            have purchased must be redeemed within a six-months-period after transmission, and that the activation code
            in case of non-redemption within the six-month- period will lapse. Expired codes will not be replaced or
            refunded. All codes which were not used on time were automatically marked as invalid and unusable.
          </li>
        </ol>

        <h4 className="terms-paragraph-title">
          12. NON-COMPETITION CLAUSE / VIOLATIONS / SALE OF THIRD-PARTY SERVICES/ CONFLICT OF INTERESTS.
        </h4>
        <ol className="terms-paragraph">
          <li>
            The IMAs are allowed to sell goods and/or services for other companies, including network marketing
            companies, to the extent that such are not competitors of the Company. If the IMAs are simultaneously active
            for several companies or network marketing companies, they consent to structuring their business operations
            (as well as their individual downlines) to prevent any overlap or link between their work for the Company
            and their work for these other companies. In particular, the IMAs shall not offer products other than the
            Company&apos;s products at the same time, in the same place or in close proximity, or on the same website,
            Facebook page or other social media/online platform.
          </li>
          <li>
            The IMAs are also prohibited from recruiting other IMAs for the sale of other Company&apos;s products.
          </li>
          <li>
            The IMAs are also prohibited from infringing on the rights of other IMAs or the terms of existing sales
            contracts that they have entered into with other companies and are still in force by entering into another
            contract.
          </li>
        </ol>

        <h4 className="terms-paragraph-title">13. CONFIDENTIALITY.</h4>
        <ol className="terms-paragraph">
          <li>
            The IMAs shall keep strict confidentiality regarding business and trade secrets of the Company and its
            structure. In particular, the trade secrets include data regarding third parties, partners of the Company,
            or other IMAs, as well as information on downline activities and the information contained within. This
            obligation shall remain in force even after termination of the relationship between the Company and the IMA.
          </li>
          <li>
            IMAs shall use the confidential Information exclusively for the purpose of implementing and executing the
            contractual relationship with Company in accordance with the present T&C.
          </li>
          <li>
            The disclosure of confidential information by the IMAs to third parties requires the prior written consent
            of the Company.
          </li>
          <li>The duty of confidentiality shall not apply if the respective confidential information can be proven:</li>
          <ol className="nested disc-list">
            <li>to be or to become generally known without any action from the IMAs or</li>
            <li>
              to have been already known to the IMAs or is made known by a third party authorised to pass on the
              information or
            </li>
            <li> to be disclosed due to mandatory statutory conditions or court or official orders.</li>
          </ol>
          <li>
            The IMAs shall be responsible for their compliance with all relevant statutory data protection provisions,
            in particular for the lawfulness of the data disclosure and data processing of personal data in connection
            with the activity like an IMA.
          </li>
        </ol>

        <h4 className="terms-paragraph-title">14. DATA PROTECTION.</h4>
        <ol className="terms-paragraph">
          <li>
            The Company may obtain information (including personal data) from the IMA throughout their relationship.
            This section describes some of the key issues on how the Company processes this personal data, and every IMA
            should be aware of them. Please note that there are other documents and information, which form part of the
            T&C and provide more details about personal data, and are available on our website.
          </li>
          <li>
            By accepting these T&C, you agree and acknowledge that we collect and process your data to enter into and
            fulfil our contract and comply with all statutory obligations. The services covered by these obligations
            include:
          </li>
          <ol className="nested disc-list">
            <li>the opening and maintaining of accounts</li>
            <li>your network marketing activities</li>
          </ol>
          <li>Access to processed personal data shall only be granted to authorised persons.</li>
          <li>
            If the IMA refuses to provide personal data, we may not be able to provide services to him/her. More details
            about the type of personal data we collect and how it is used are available in the Data Protection Notice.
            Data Protection Notice (DPN) can be found in Schedule D to this T&C.
          </li>
          <li>
            The Company collect and process personal data which is subject only to the requirements of the applicable
            legislation. The processing of personal data may only be executed with a specific reason in focus; the
            personal data may not be processed without limitation. Except for fulfilment of the agreement and in the
            context of pre-contractual relations with the IMA, we may collect and process personal data to comply with
            our legal obligations and in cases where the IMA has provided his/hers consent (where applicable).
          </li>
          <li>
            Every IMA acknowledges and agrees that this description is not exhaustive and that our Data Protection
            Notice contains additional pertinent information. Our Data Protection Notice is part of these T&C, and every
            IMA declares that he/she has read it.
          </li>
          <li>
            The Company shall not disclose any of the personal and/or confidential information unless required to do so
            by a regulatory authority of a competent jurisdiction. Such a disclosure shall occur on a &apos;need-to-know
            basis&apos;, unless otherwise instructed. Under such circumstances, we shall expressly inform the third
            party regarding the confidential nature of the information.
          </li>
          <li>
            Every IMAs agrees and declares that all the information provided by him/her and submitted to us is correct
            and that he/she is obliged to inform us immediately in case of any changes to this information or other
            information regarding the Agreement.
          </li>
          <li>
            By using our services and Website, and by accepting this General Terms and Conditions every IMA agrees and
            declares that:
          </li>
          <ol className="nested disc-list">
            <li>He/she voluntarily submitted his/her personal data</li>
            <li>
              He/she allows us to collect, store and process his/her personal data in connection with the provision of
              network marketing activities and additional services and fulfillment of our legal obligations
            </li>
            <li>
              He/she allows us to disclose his/her personal data to the competent state authorities supervising the
              Company, as well as to other persons when required by a legal act
            </li>
            <li>
              He/she has received the information pursuant to Article 19, paragraph 1 of the Personal Data Protection
              Act and Article 13 of the Regulation (EU) 2016/679 of the European Parliament and of the Council of 27
              April 2016, namely the General Data Protection Regulation (GDPR), when applicable.
            </li>
          </ol>
          <li>
            After termination of the relationship, the data will be retained for a certain period of time depending on
            the nature of the information held and the purposes for which the information has been processed. We
            determine the appropriate retention period with regard to any statutory obligations imposed by law.
          </li>
          <li>
            The Company may collect, store and process information obtained from the IMA or otherwise in connection with
            the services we provide for the purpose of complying with applicable laws, rules and/or regulations,
            including disclosures to governmental authorities.
          </li>
          <li>
            By accepting this T&C, you authorise us to provide, directly or indirectly, to any relevant tax authorities
            or any party authorised to audit or conduct a similar inspection for tax purposes information obtained from
            you or otherwise in connection with the services and to disclose to such tax authorities any additional
            information that we may have in our possession that is relevant to his/her account. <br /> <br /> By using
            our services and our Website, and by accepting this T&C, the IMAs HEREBY CONFIRM THAT ALL THE DATA,
            DOCUMENTS, DECLARATIONS AND INFORMATION they HAVE PROVIDED REGARDING RELATIONSHIP WITH US IS TRUE AND
            ACCURATE.
          </li>
        </ol>

        <h4 className="terms-paragraph-title">15. COPYRIGHTS AND OTHER RIGHTS OF INTELLECTUAL PROPERTY.</h4>
        <ol className="terms-paragraph">
          <li>
            All Website content is exclusive intellectual property of the Company and is protected by the copyright laws
            and other related rights and may not be used in any way without the Company’s prior written consent.
          </li>
          <li>
            The unauthorised use of intellectual property constitutes a law violation, which shall result in civil,
            administrative and criminal penalties.
          </li>
          <li>
            The Company’s trademark shall not be copied or used partially or as a whole without the Company`s express
            written consent. Other trademarks which appear on the website may also be a property of the Company or of
            other owners and may require their explicit consent prior to their use.
          </li>
          <li>
            The materials supplied on the Website are for informational purposes and may be downloaded onto a single
            computer for personal non-commercial use only, as long as they are not altered in any way, included in
            another work, or have any intellectual property rights notices removed or modified. The above rights are
            merely non-exclusive licenses to use; no ownership or rights are being transferred in any way. At any
            moment, the Company may, in its sole discretion, revoke or terminate the previously mentioned license.
          </li>
        </ol>

        <h4 className="terms-paragraph-title">
          16. TRANSFER OF BUSINESS OPERATIONS OR THE SPONSORED STRUCTURE TO THIRD PARTIES / DEATH OF THE IMA.
        </h4>
        <ol className="terms-paragraph">
          <li>
            The Company can transfer its contractual position at any time to a successor company that shall continue the
            business covered by these General Terms and Conditions and the IMA Agreement in the same manner and will
            fully assume the former company’s existing rights and obligations. The Company is entitled to use the
            services of supporting companies. The above-mentioned companies’ aim is to facilitate the provision of the
            services, including payments; technical and administrative operations of the Company. Those companies are
            independent separate entities, for which the relevant to their place of registration laws shall be applied.
            The Company is not and shall not be held liable for any activities of those partnering companies, unless any
            occasional violations of the relevant and applicable legislations are caused by gross negligence or
            intentional actions of the Company, its employees or agents/partnering companies.
          </li>
          <li>
            The IMA cannot transfer his/her downline structure/position to a third party (IMA from the same or another
            network). If a breach of these General Terms and Conditions is established, the Company keeps its right to
            sanction the IMA by freezing or permanently terminating their accounts.
          </li>
          <li>
            The IMA Agreement shall be terminated with the death of the IMA at the latest. It may be passed on as an
            inheritance when required by law. As a rule, the heir(s) must sign a new IMA Agreement within six months of
            the previous IMA&apos;s death and therefore take over the deceased IMA&apos;s rights and obligations. The
            death must be proven by means of a death certificate. Should there be a will granting the IMA Agreement as
            part of the deceased&apos;s inheritance, a notarized copy of the will shall be presented. The six-month
            period may be extended by a reasonable length of time on an exceptional basis, if it is disproportionately
            short for the heir(s).
          </li>
          <li>
            In the event that a married couple or life partners, registered as IMAs terminate their association
            internally, there shall still be only one IMA position even after the separation, dissolution or other
            termination of the above- mentioned association. Those members, who leave the partnership, shall decide
            internally which member shall continue the IMA Agreement and provide written notice of their decision to the
            Company. In the event of an internal dispute over the consequences of the separation, divorce, dissolution
            or other termination with respect to the participation in the Network system, the Company reserves the right
            of an abrupt termination, when a disagreement of this kind results in an undue burden on the downline or
            upline, a breach of the applicable laws, a neglect of duty on the part of the IMA, or violation of any of
            these General Terms and Conditions.
          </li>
        </ol>

        <h4 className="terms-paragraph-title">
          17. PROTECTION OF DOWNLINE PARTNERS / CROSSLINE SPONSORING / BONUS MANIPULATION.
        </h4>
        <ol className="terms-paragraph">
          <li>
            The Company is entitled to delete all personal data, including the email address of the IMA from the system,
            if advertising mail, letters or emails are returned and marked “moved”, “deceased”, “rejected”, “unknown”
            etc. and the IMA fails to correct the false data within a reasonable grace period. If the Company incurs any
            costs for undeliverable advertising items and packages, it is entitled to recover said costs from the
            registered IMA, provided that they are responsible for such failure to deliver.
          </li>
          <li>
            Every new IMA recruited by an active IMA to register as an IMA in Network system or to sell its products
            will be assigned to that same recruiting IMA (protection of downline partner) within its downline based on
            the time and date that the new IMA`s application was paid and received by the Company. If two IMAs claim to
            have sponsored the same new IMA, the new IMA will be assigned only to that recruiter named in the new
            partner&apos;s initial application.
          </li>
          <li>
            In addition, cross-line sponsorships and all attempts to do the same within Company&apos;s system are
            prohibited. Cross-line sponsoring means the acquisition of a person or a company, who/which is already an
            IMA in another Company&apos;s sales line or that has had business relations with the Company within the last
            12 months. Using the names of spouses, relatives, trade names, corporations, partnerships, trusts, or other
            third-party names to overlook this clause is also prohibited.
          </li>
          <li>
            Bonus manipulation is prohibited. In particular, this includes the sponsorship of IMAs who are actually not
            involved in business activities with the Company (also known as shell vendors), as well as, open or
            concealed multiple registrations, to the extent that these are prohibited. The use of names of spouses,
            relatives, trade names, corporations, partnerships, trusts, or other third-party names to overlook this
            clause is also prohibited. The IMAs shall not attempt to protect any sales territory.
          </li>
        </ol>

        <h4 className="terms-paragraph-title">18. WARNINGS / CONTRACTUAL PENALTY.</h4>
        <ol className="warnings-list">
          <li>
            The first time the IMA violates the obligations set forth in p. 8 or the anti– spam policy, he/she shall
            receive a written notice from the Company with a maximum of 10 days to correct the violation. The IMAs agree
            to reimburse the Company for all costs, including legal fees, that result from these notices.
          </li>
          <li>
            It is hereby noted that p.22 (2) herein expressly gives the Company the right to immediately terminate the
            IMA Agreement without a warning in the event of any breach of the IMA&apos;s obligations outlined in p. 12,
            13, and 17 (3) or (4), as well as in the event of a particularly serious violation of the obligations set
            forth in p. 8, or of any other applicable contractual or legal right. Despite the right to immediately
            terminate the IMA&apos;s Agreement without a former notice, which is set forth in p. 22 (2), if any of the
            above-mentioned breaches of obligations occurs, the Company reserves the right at its discretion to issue a
            notice, as described in (1) above, before any such immediate termination, even if this notice stipulates a
            shorter grace period for remedial action.
          </li>
          <li>
            If the provided grace period expires and the same or a fundamentally identical breach of obligations has
            been committed, or if the originally protested breach has not been remedied, a contractual penalty of
            €7,500.00 will become due for immediate payment. In addition to the contractual penalty itself, the IMAs
            shall be responsible for reimbursing all attorneys&apos; fees associated with its collection.
          </li>
          <li>
            Despite the contractual penalty, the IMA is also accountable for every and all losses that the Company
            sustains.
          </li>
        </ol>

        <h4 className="terms-paragraph-title">19. COMPENSATION.</h4>
        <ol className="terms-paragraph">
          <li>
            The IMA shall provide compensation to the Company upon request for every and all claims made by third
            parties due to the IMA&apos;s failure to perform his/her obligations set forth in p. 8, 12,13 and p. 17 (3)
            and (4) or any other violations of applicable laws. In particular, the IMAs agree to cover all costs,
            especially attorney`s fees, court costs and damages incurred by the Company in addressing such matters.
          </li>
          <li>
            The IMAs shall compensate the Company on demand for all liabilities, costs, expenses, damages (including
            reputational) and losses (including, but not limited to any direct, indirect or consequential losses), and
            all interests, penalties and professional costs and expenses (calculated on a full indemnity basis) incurred
            by us as a result of:
          </li>
          <ol className="nested disc-list">
            <li>the IMA&apos;s breach of the Agreement</li>
            <li>any false or misleading information provided to the Company by the IMA</li>
          </ol>
        </ol>

        <h4 className="terms-paragraph-title">20. BLOCKING THE IMA.</h4>
        <ol className="terms-paragraph">
          <li>
            Should the IMA fail to provide all of the necessary documents within 30 days of registration and
            acknowledgment of the requirements for the payment of bonuses, the said IMA shall be temporarily suspended
            until such time as the documents required by law are provided. The same applies if the IMA fails to comply
            with the deadline set forth in p. 7 (8), if he/she is in a breach of p. 7 (9) until such breaches are
            rectified, or if the IMA fails to pay the fees due. Unless the IMA is not at fault for the circumstance that
            led to the suspension, the suspension period does not grant the IMA the right to an immediate termination,
            to rise a claim for damages, or to grant a refund of any first orders and starting packages that have
            already been paid for.
          </li>
          <li>
            Bonus claims that cannot be paid for due to the reasons listed above will be posted as accrued liabilities
            in the Company&apos;s bookkeeping system and shall expire no later than the deadlines set forth by law.
          </li>
          <li>
            The Company is entitled to a refund for the expenses incurred for each case where providing a notice has
            been required.
          </li>
          <li>
            Regardless of the reason for the suspensions mentioned in p. 20 (1), the Company reserves the right to block
            the IMA&apos;s access without complying with the notice period if the IMA is in a breach of the obligations
            mentioned in p. 8, 12,13 and p. 17 (3) and (4). The Company is entitled to an immediate termination if the
            IMA violates any other applicable laws, or, alternatively, if there is an important reason, and if the IMA
            fails to compensate for the corresponding breach of obligations despite receiving an appropriate warning
            notice within the extension period specified in p. 10.
          </li>
        </ol>

        <h4 className="terms-paragraph-title">21. EVENTS OUTSIDE COMPANY&apos;s CONTROL (FORCE MAJEURE).</h4>
        <ol className="terms-paragraph">
          <li>
            This section refers to events that may occur occasionally, preventing us from performing some or all of
            Company&apos;s obligations or services (&apos;Force Majeure Events&apos;). Force Majeure events may include,
            but shall not be limited to:
          </li>
          <ol className="nested disc-list">
            <li>
              any natural, technological, political, governmental, social, economic, acts of God, medical, pandemic,
              civil emergency, acts of terror, interruption or failure of utility service;
            </li>
            <li>
              non-performance on the part of a third party, man-caused destruction or any similar event which is beyond
              our reasonable control;
            </li>
            <li>instances of improper behaviour, errors, malfunctions, disturbances in systems;</li>
            <li>
              technological or other infrastructure (regardless if it belongs to us or a third party) against servers;
            </li>
            <li>
              changes in the applicable legislation, any action of an official body or any other change in Company’s
              legal or regulatory obligations as a result of unforeseen events;
            </li>
            <li>
              an act or omission by any financial or other institution, bank or payment provider that Company is unable
              to predict and/or prevent;
            </li>
            <li>any event that prevents the Network or the systems from operating in an orderly or normal manner;</li>
            <li>
              abnormal market conditions, such as significant volatility or instability in the markets, or the industry
              as a whole, preventing us from providing our services in an orderly manner, including any instances where
              we are unable to receive data and/or we receive incorrect data from our service providers;
            </li>
            <li>
              any other event and/or circumstance which cannot be foreseen, within reason. <br />
              For the avoidance of doubt, a force majeure event is an uncontrollable event that, although it is
              conceivably likely to happen or could happen soon, cannot be predicted for which the Company cannot
              provide adequate preparation or for which the Company cannot stop it from happening.
            </li>
          </ol>
          <li>
            In the event of determining that that a Force Majeure Event has taken place, without violating any rights of
            ours under these T&C, or the law, the Company may pursue any of the following course of action(s):
          </li>
          <ol className="nested disc-list">
            <li>inform the IMA, when the Company has sufficient time to do so under the circumstances;</li>
            <li>require additional documents, declarations and/or information from the IMA;</li>
            <li>suspend, limit or restrict the provision of services;</li>
            <li>restrict the IMA from accessing or using the Network, Backoffice, Website or other systems;</li>
            <li>amend any of the content of the current T&C;</li>
            <li>terminate the current T&C and relations.</li>
          </ol>
          <li>
            As soon as it is reasonably practicable, the Company shall make every effort to resume the orderly provision
            of services. If this isn&apos;t possible, the Company will, if it can, let you know what has to be done to
            protect both your and our interests.
          </li>
        </ol>

        <h4 className="terms-paragraph-title">
          22. DURATION AND TERMINATION OF THE IMA AGREEMENT, CONSEQUENCES OF TERMINATION / REFUNDS / RETURN /
          CANCELLATION
        </h4>
        <ol className="terms-paragraph">
          <li>
            The IMA Agreement (an entire agreement between the Company and its IMAs, together with an inseparable part
            of the present General Terms and Conditions and Related Documents) is concluded for an unlimited period and
            may be terminated by either party at any time, even within the contractual period - with one month’s notice
            prior to the end of the next calendar month.
          </li>
          <li>
            Despite the causes for termination in accordance with (1) above, the Company reserves the right to terminate
            the relationship for every reasonable cause even if not explicitly listed. Such important reason is a breach
            in any of the obligations set forth in p. 8, if the IMA fails to remedy the same in a timely manner as set
            forth in p. 18 (1), or if, after such remedy, the IMA violates once again the same or a similar clause. For
            any breach of the obligations set forth in p. 12, 13 and 17 (3) and (4), 16 (4) or (6) as well as any
            particularly severe breach of the obligations set forth in p. 8 or any other applicable contractual or
            statutory law, the Company is entitled to an immediate termination of the IMA Agreement without prior
            notice. In addition, each party is entitled to an immediate termination of the IMA Agreement if insolvency
            procedures have been initiated against the other party or if such proceedings were withdrawn due to a lack
            of assets, also if the other party is otherwise insolvent or has submitted an affidavit of insolvency in
            response to an attempt to enforce a judgment by writ. The right to immediate termination shall not affect
            any other claims.
          </li>
          <li>
            Domains that include the name of the Company, the Network or any other brand, business, or product name
            owned by the Company or its business partners may no longer be used by the IMA after the IMA Agreement has
            been terminated.
          </li>
          <li>
            Unless the IMA has exercised the right to immediate termination due to an important reason, an early
            termination of the IMA Agreement with a minimum term does not entitle the IMA to receive reimbursement
            payments.
          </li>
          <li>
            The IMA may re-register in the Network after cancelling their previous IMA Agreement, provided that said
            cancellation and confirmation of the cancellation by the Company were minimum 12 months ago and the said IMA
            has not since performed any activities for the Network in the interim.
          </li>
          <ol className="nested disc-list">
            <li>
              All IMAs with at least 1 account with an &quot;Active&quot; status are considered as active IMAs. The
              account is considered &quot;Active&quot; if it has completed the following actions in the last 12 months
              from the last account check:
            </li>
            <ol className="nested circle-list">
              <li>
                The IMA should purchase and redeem an item from the Network Catalog OR has &quot;Active merchant&quot;
                status in Dealshaker platform
              </li>
              <li>The IMA account must be deployed to Polygon network</li>
              <li>The IMA profile should be KYC verified</li>
            </ol>
            <li>
              If the account does not fulfil the above-mentioned requirements, the Account&apos;s status will be changed
              to &quot;Inactive&quot;, thereby revoking all benefits and privileges in the Leadership and Accounting
              programs, Compensation plan as well as the accumulation of points.
            </li>
          </ol>
          <li>
            The IMA has the right to terminate his/her business relations with the Company and withdraw from the IMA
            Agreement only if he/she has not logged in to his/her account, started using the platform and got access to
            the product. Once the IMA Agreement has been terminated, the IMA shall have no rights to bonus payments and
            to sales agent indemnification payment claims, since the IMAs are not sales agents.
          </li>
          <li>
            The IMA may return the purchased Company products in the following circumstances (excluding personalised or
            customised items):
          </li>
          <ol className="nested disc-list">
            <li>
              The purchased educational package shall only be refunded in case the IMA has not logged in for the period
              of 14 (fourteen) days from the date of submitting the application, unless there is a mandatory term,
              stated by the applicable law.
            </li>
            <li>
              No refunds shall be made once the IMA has logged in his/her account. It is considered that by logging in
              the IMA accepts the General Terms and Conditions and no refunds of his/her payment shall be made.
            </li>
          </ol>
          <li>
            The Company shall process the return promptly upon verifying that the IMA is eligible for a refund. In most
            cases, the IMA is expected to receive the refund within 30 working days of receiving the termination
            notification by the Company.
          </li>
          <li>
            The IMA hereby acknowledges and agrees that the Company`s return policy does NOT apply in cases where the
            IMA has already received commission payouts, or other benefits, as a result of using the Company&apos;s
            products/goods and/or services.
          </li>
          <li>
            All refund requests shall be made within 14 (fourteen) days of submitting the application. The return
            request will deactivate the initial order instructions and will delete the IMA`s unique identification (user
            name) for the Training and Rewards Package. This cancellation policy is designed to protect the ALS training
            and education materials because it is proprietary intellectual property. The IMA`s activation and position
            in the compensation system will be cancelled. Any commissions paid to returnee will be deducted from the
            refund amount.
          </li>
          <li>
            Whenever the IMA requests services from the Company that are not covered by the IMA Agreement and these
            General Terms and Conditions, these services will remain unaffected after the termination of said IMA
            Agreement, unless the IMA expressly requests their discontinuation in his/her termination notice. Should the
            IMA receive a service from the Company after terminating the IMA Agreement, he/she shall be treated as a
            normal client.
          </li>
        </ol>

        <h4 className="terms-paragraph-title">23. ADJUSTMENTS OF PRICES/ INCLUSION OF THE COMPENSATION PLAN.</h4>
        <ol className="terms-paragraph">
          <li>
            The Company reserves the right to change the licensing structure and/or the fees to be paid by the IMAs,
            particularly in the light of any shifting market conditions. Likewise, the Company reserves the right to
            change, and, in particular, increase, the shares in bonuses associated with its services, the Compensation
            plan and the usage fees at the beginning of each new billing period. The IMAs will be given a notice in
            advance for such changes. The IMAs may object to modifications to the remuneration plan that negatively
            impact the organization or that increase prices by more than 5%. Should the IMAs not object to the changed
            conditions within one month after their announcement, the changed terms shall become an integral part of the
            present General Terms and Conditions. There is no requirement to notify the IMA of any changes that are
            known at the time of submitting his/her application and such changes do not constitute grounds for future
            rejection of the same by the IMA. If the IMAs object to any change in the present General Terms and
            Conditions, the Company is entitled to terminate the IMA Agreement as of the day the modified or amended
            General Terms and Conditions shall come into force.
          </li>
          <li>
            The Company&apos;s Compensation Plan and the requirements contained therein are also explicitly part of the
            present General Terms and Conditions and the IMA Agreement. The IMA shall comply with the clauses from the
            current version of these General Terms and Conditions.
          </li>
          <li>
            By submitting an online application to the Company, the IMA agrees that he/she has read and understood the
            Compensation Plan and accepts these documents as integral component of the present General Terms and
            Conditions.
          </li>
          <li>
            The Company is entitled to change the Compensation Plan at any time. The Company will announce any general
            amendments within a reasonable period. The IMA has the right to object to the amendments. In case of an
            objection the IMA is entitled to terminate the IMA Agreement at the date of entry into force of the
            modification. In case he/she does not terminate the IMA Agreement within two weeks after the entry into
            force of the modification, the IMA expressly accepts the modification.
          </li>
        </ol>
        <h4 className="terms-paragraph-title">24. EXCLUSION OF LIABILITY.</h4>
        <ol className="terms-paragraph">
          <li>
            The Company&apos;s obligations under the present T&C do not constitute personal obligations of our
            directors, officers, shareholders, members, employees, representatives, third-party service providers and/or
            any other third parties.
          </li>
          <li>
            In the event that the Company outsources to third parties to handle any activity, the Company will exercise
            all reasonable endeavours to deliver our services before contracting them. However, the IMA understands that
            it is not possible to control the activities of such third parties. The Company&apos;s responsibilities,
            therefore, shall be to exercise all efforts to minimise losses that the IMA may suffer as a result of an act
            and/or omission of the outsourced party(ies).
          </li>
          <li>
            The IMA recognizes and agrees that any relationship he/she establishes with us is done so at his/her own
            risk, and the Company disclaims all responsibility for any losses he/she may incur from relations with us.
          </li>
          <li>
            The Company shall not be liable for any direct, indirect, consequential, incidental and/or special losses
            (including, but not limited to loss of profits, trading losses, or damages) which have resulted from a
            breach of the present T&C.
          </li>
          <li>
            Further, and despite every other clause in the present T&C, the Company will not be liable as a result of:
          </li>
          <ol className="nested disc-list">
            <li>
              negligence, fraud, abuse, breach of the present T&C, breach of any policy, law and/or any other act and/or
              omission by IMA;
            </li>
            <li>unavailability of the software and/or backoffice, the Website and systems;</li>
            <li>such a loss or damage that no breach would not have been reasonably expected to cause;</li>
            <li>any other event and/or circumstance which is outside Company&apos;s control.</li>
          </ol>
          <li>
            The Company shall take no responsibility for any loss incurred as a result of the acts or omissions of any
            institution (such as banks, payment providers, service providers) or its employees, including but not
            limited to instances of false or misleading information provided by the IMA.
          </li>
          <li>
            The Company shall take no responsibility if either a natural or legal person attains unauthorised access to
            any information.
          </li>
          <li>
            In no case shall the Company be responsible for any loss or damage, whether direct or indirect, resulting
            from use, continued use, or reliance on the Website, newsletters, software, backoffice, or other systems; in
            particular, this will not apply to any market data, news, headlines, graphs, or other information that the
            Company and/or any third-party service provider provide in connection with services. Тhe IMA acknowledges
            that any such data or information is the Company&apos;s property and/or the property of our third-party
            service providers and the IMA shall not retransmit or disclose such data or information to third parties
            except as required by the present T&C and/or relevant law.
          </li>
          <li>
            The Company takes no responsibility in the event that any viruses, worms, software bombs or similar items
            are introduced into the IMA&apos;s computer system(s) via the Website, software, backoffice or other systems
            or any software provided by the Company to the IMA in order to enable him/her to use the services. The IMA
            will ensure that no computer viruses, worms, software bombs or similar items are introduced into our systems
            or network(s) and in case the Company experiences any loss as a result of such introduction, the IMA will
            reimburse us upon request.
          </li>
          <li>
            The Company takes responsibility for any damages other than harm to life, limb or health only when the
            Company, its employees, or its agents do wilful or seriously negligent conduct or culpably breach material
            contractual duties (e.g. payment of bonuses). This also applies to damages resulting from the breach of
            obligations in contract negotiations as well as in the event of torts. Any further liability for damages is
            excluded. The Company, its employees, or its agents shall not be liable for any damages other than those
            that would have been reasonably foreseeable at the time the IMA Agreement was concluded, nor for any damages
            other than those that would have resulted from intentional or egregiously negligent acts. This also applies
            to indirect damages, including, in particular, loss of profits.
          </li>
          <li>
            The Company is furthermore not responsible for any of its IMAs&apos; claims about income, team building or
            others. The Company has ZERO tolerance when comes to unprofessional behaviour that can in any way shape or
            form harm its business reputation. If the IMA encounters unprofessional behaviour, he/she must immediately
            notify the Company so that the appropriate actions against the said are taken timely.
          </li>
        </ol>
        <h4 className="terms-paragraph-title">25. AMENDMENTS.</h4>
        <ol className="terms-paragraph">
          <li>
            The Company is entitled to change the General Terms and Conditions at any time, where modifications will be
            announced within a reasonable period. The IMA is entitled to object to the amendment. In case of an
            objection, the IMA is entitled to terminate his/her relations with the Company at the date of entry into
            force of the modification. In case the IMA continues using the services and does not terminate his/her
            relations with the Company within two weeks after the entry into force of the amendment, the IMA expressly
            accepts the amendment.
          </li>
          <li>
            Furthermore, amendments and additions to these General Terms and Conditions are to be made in writing. This
            shall also apply to the revocation of the written form requirement.
          </li>
          <li>
            It is the IMA&apos;s responsibility to stay informed of any amendments made by the Company to the T&C. The
            latest version that is available on the Website at any given time is the version that applies. In the event
            of a dispute, the latest version available at the time of the dispute shall prevail.
          </li>
        </ol>

        <h4 className="terms-paragraph-title">26. DISPUTE RESOLUTION.</h4>
        <ol className="terms-paragraph">
          <li>
            Any claims resulting from this contractual arrangement expire six months after the corresponding claim
            becomes due and the entitled party is aware of all the circumstances supporting the claim, unless their
            ignorance was the result of gross negligence. Legal clauses requiring a longer limitation period shall
            remain unaffected.
          </li>
          <li>
            The Parties agree to cooperate in settling any disputes that may arise about the structure, interpretation
            and effects of the present General Terms and Conditions and other related to them questions or their
            subject-matter.
          </li>
          <li>
            The Arbitration Rules, which are presumed to be included by reference into this clause, shall be applied to
            and finally resolve any disputes related to commercial and inter-company concerns. The arbitration process
            shall include specifically:
          </li>
          <ul className="nested disc-list">
            <li>The number of arbitrators shall be three.</li>
            <li>
              The language to be used in the arbitral proceedings shall be English. Any documentation not presented in
              English shall be translated to English at the expense of the party submitting them.
            </li>
          </ul>
          <li>
            Any disputes between the Company and its IMAs, related to the present General Terms and Conditions and
            particularly their existence, governance, validity or termination, shall be referred to and finally resolved
            by binding arbitration under the Arbitration Rules of the Arbitration Court at the Vietnamese legislation of
            Commerce and Industry.
          </li>
        </ol>

        <h4 className="terms-paragraph-title">27. MISCELLANEOUS.</h4>
        <ol className="terms-paragraph">
          <li>
            Any part of this document and/or any particular clause that a court of competent jurisdiction finds to be
            unenforceable for any reason shall be severed from the remainder of the T&C or the term, and the remaining
            portion of the General Terms and Conditions shall remain unaffected and enforceable.
          </li>
          <li>
            The bolding of certain paragraphs, words or phrases is merely for convenience of reference. You should read
            the present General terms and Conditions in full.
          </li>
          <li>
            By accepting the present General Terms and Conditions the IMA declares and agrees that he/she fully
            understands and accepts the hereto stated rights and obligations, including all relevant and applicable
            internal rules, together with those described in the IMA Agreement and the Compensation Plan of the Company.
          </li>
        </ol>
        <p className="terms-paragraph">Version: 20.09.2024</p>
        <p className="terms-paragraph center">
          ------------------------------------------------END--------------------------------------------------
        </p>

        <h5 className="terms-paragraph-title center">
          SCHEDULE A <br /> to the General Terms & Conditions
        </h5>
        <h6 className="terms-paragraph-subtitle center">DEFINITIONS</h6>
        <ul className="terms-paragraph">
          <li>
            <b>Independent Marketing Associate /IMA/</b> - The term IMA as used in these General Terms and Conditions
            means persons who may earn commissions, bonuses and other economic benefits through participation in plan of
            a network marketing business and the promotion or sale of products or services and who may earn commissions,
            bonuses and other economic benefits through referring others to participate, promoting promote, sell
            products or services or introduce others. Persons who enter into a contract with the Company after
            fulfilling specific conditions under which they may qualify to promote or sell products or services or refer
            others to participate shall be considered as member from the time of signing the contract.
          </li>
          <li className="bolded-spans">
            Company - ONE ECOSYSTEM WORLD JOINT STOCK COMPANY, SH25-K7.TT1, Starlake Urban Area, West Lake, Xuan La
            Ward, Tay Ho District, Hanoi City, Vietnam
          </li>
          <li>
            <b>Products and Services</b> - online educational programs, Company Academy - to ensure people around the
            world have equal access to practical Financial, Blockchain and Forex Trading Education and relevant
            educational knowledge, thereby helping to promote the process of Natural Education Development. Advertising
            codes, such as accumulated currency points, exist only as electronic records in the system, and neither they
            nor the rights that can be exercised through them are created in a tangible form. Operations related to ONES
            points can only be performed in the system provided by the Company.
          </li>
          <li>
            {' '}
            <b>Network</b> - network of OneEcosystem.
          </li>
          <li>
            <b>Website</b> – means oneecosystem.eu.
          </li>
          <li>
            <b>Company&apos;s system</b> - OneEcosystem is as revolutionary business concept that aims to bring numerous
            opportunities to those who want to take charge of their life.
          </li>
          <li>
            <b>UCE</b> - Unsolicited Commercial email, or SPAM
          </li>
          <li>
            <b>Profile</b> - contains all personal information records of each IMA, such as: Name, Date and place of
            birth, password, Identification information etc. and each IMA can have 1 personal profile. A profile is
            unrelated to the binary network structure used by website except accounts.
          </li>
          <li>
            <b>Account</b> - The account is the only record containing all the portfolio information, Business Volume
            balance and position in the binary network structure that Company uses. Each IMA can have more than one
            account in her/his profile.
          </li>
        </ul>

        <h5 className="terms-paragraph-title center">
          SCHEDULE B <br />
          to the General Terms & Conditions
        </h5>
        <h6 className="terms-paragraph-subtitle center">ETHICAL RULES</h6>
        <p className="terms-paragraph">Ethical rules for dealing with clients</p>
        <ul className="terms-paragraph">
          <li>
            Our IMAs advise their clients honestly and sincerely, clarifying any misunderstandings about goods, the
            business opportunity, or other statements made during consulting discussions.
          </li>
          <li>
            At the clients&apos; request, the sales conversation can be omitted, postponed, or amicably discontinued
            once started.
          </li>
          <li>
            When contacting a client, the IMA shall inform the client about all aspects of the product (such as purpose,
            characteristics, or application) and also, if requested, concerning the potential for further sales.
          </li>
          <li>All information provided about the goods must be comprehensive and truthful.</li>
          <li>
            The IMA may not make any claims, promises or other indications about potential speculative changes in
            exchange rates.
          </li>
          <li>
            The client should not be led to purchase the products through dubious and/or misleading promises nor by
            promising special benefits when these are linked to uncertain future outcomes.
          </li>
          <li>
            The IMA may not make any comments with respect to their compensation or the potential remuneration of other
            IMA. Furthermore, the IMA may not guarantee payments or otherwise raise expectations.
          </li>
          <li>
            The IMA may not claim that the Compensation Plan or goods have been endorsed, approved, or supported by any
            government and/or agency.
          </li>
        </ul>
        <p className="terms-paragraph">Ethical rules for dealing with IMA</p>
        <ul className="terms-paragraph">
          <li>
            IMAs must always treat each other fairly and respectfully. The same also applies to dealing with partners in
            other network marketing companies.
          </li>
          <li>
            New IMAs should be informed truthfully about their rights and obligations. No information should be provided
            concerning potential revenue and earnings opportunities.
          </li>
          <li>No verbal assurances may be made regarding the Network goods and services.</li>
          <li>
            IMAs are not allowed to poach IMA from other companies. Furthermore, IMAs are not allowed to suggest that
            other IMAs change their sponsor within the Network.
          </li>
          <li>
            Compliance with the obligations of the General Terms and Conditions in addition to these Ethical rules is
            required at all times.
          </li>
        </ul>
        <p className="terms-paragraph">Ethical rules for dealing with other companies</p>
        <ul className="terms-paragraph">
          <li>
            Company Network IMAs will always be fair and honest in their conduct towards other network marketing
            companies.
          </li>
          <li>The systematic recruitment (poaching) of partners is not permitted.</li>
          <li>
            Negative statements, misrepresentations or unfair comparisons about other companies&apos; products, services
            or distribution systems are prohibited.
          </li>
        </ul>
        <h5 className="terms-paragraph-title center">
          SCHEDULE C <br />
          to the General Terms & Conditions
        </h5>
        <h6 className="terms-paragraph-subtitle center">ANTI-SPAM POLICY</h6>
        <p className="terms-paragraph">Definition of UCE (Unsolicited Commercial email), or SPAM:</p>
        <ul className="terms-paragraph">
          <li>
            The bulk UCE, promotional material, or other forms of solicitation sent via email that advertise any IP
            address belonging to the Company or any URL (domain) that is linked to&nbsp;
            <a href="www.portal.oneecosystem.eu" target="_blank" rel="noreferrer">
              www.portal.oneecosystem.eu
            </a>
            &nbsp; and / or other web sites owned or operated by The Company.
          </li>
          <li>
            The use of web pages set up on ISPs (Internet Service Providers) that allow SPAM (also known as “ghost
            sites”) that directly or indirectly reference clients to domains or IP addresses linked by&nbsp;
            <a href="www.portal.oneecosystem.eu" target="_blank" rel="noreferrer">
              www.portal.oneecosystem.eu
            </a>
            &nbsp; and / or other web sites owned or operated by the Company.
          </li>
          <li>
            Advertising, transmitting, or otherwise making available any software, program, product, or service that is
            designed to facilitate a means to SPAM.
          </li>
          <li>
            Forging or misrepresenting message headers, whether in whole or in part, to mask the true origin of the
            message.
          </li>
        </ul>
        <div className="terms-paragraph">
          <p>
            Repercussions of SPAM:
            <br />
            <br />
            Across the Web, it is generally accepted that SPAM is an inconsiderate and improper business practice. SPAM
            is not only harmful because of its negative impact on client&apos;s attitudes toward the Company, but it can
            also overload the Company, resources and other services.
          </p>
          <p>
            <p>
              Our Providers:
              <br />
              Since it is unsolicited, users who receive SPAM often become angry and send complaints to our service
              providers. It can cause negative client attitudes and drain resources. The Company strives to maintain
              favourable business relationships in the Web community and obviously will not allow any practice that
              threatens these relationships.
            </p>
            <br />
            Consequences for use of SPAM:
            <br />
            The Company reserves the right to terminate, without warning, any IMA that violates this policy. Usage of
            the Company’s system and services constitutes acceptance and understanding of this policy. The Company
            reserves the right to decide what it considers “SPAM”, “UCE”, “mail bombing”, or “bulk email”, and to
            determine from all of the evidence whether or not the email recipients were from an “opt-in” email list.
            <br />
            <br />
            If the IMA or the IMA&apos;s Web site is mentioned in a SPAM complaint, said IMA may be subject to immediate
            termination.
            <br />
            <br />
          </p>
          <p>
            The Company will not allow the actions of a spammer to compromise the Company&apos;s IMA community. The
            following actions against a violating IMA shall be applicable:
          </p>
          <ul className="schedule-c-list">
            <li>Termination of position and Web site immediately without a refund of any kind;</li>
            <li>Forfeit use of all services and products of the Company; </li>
            <li>Face a possible fine of €250;</li>
          </ul>
          <p>
            The violating IMA exposes himself/herself to all civil and criminal liabilities in the jurisdictions
            applicable.
            <br />
            <br />
            Please Note: IMA must pay €250 to €500 to any of the Company&apos;s service providers, partners or
            individuals for excessive SPAM complaints. If the IMA is the user who is violating the ANTI-SPAM POLICY
            resulting in the Company having to pay €250 to €500 penalty, the violating IMA WILL BE HELD RESPONSIBLE AND
            MUST PAY THE FINE.
            <br />
            <br />
            The Company reserves the right to determine what violates its ANTI-SPAM POLICY.
            <br />
            <br />
            This ANTI-SPAM POLICY and all other Company&apos;s policies are from time to time subject to change without
            notice. Continued usage of the services after a change to this policy is implemented and posted on the
            Website constitutes an IMA&apos;s acceptance of such change or policy. The Company encourages all IMAs to
            regularly review and check the{' '}
            <a href="www.portal.oneecosystem.eu" target="_blank" rel="noreferrer">
              www.portal.oneecosystem.eu
            </a>
            &nbsp; site for any changes or additions.
            <br />
            <br />
            Furthermore, the use of false headers in emails or falsifying, forging or altering the origin of any email
            in connection with the Company; Network system and/or products is strictly prohibited.
            <br />
            <br />
            If a person or entity indicates that they do not want to receive email, IMA agree not to send email to such
            person or entity. If a person initially agrees to receive email, but later asks to stop receiving email, IMA
            must abide by that request.
            <br />
            <br />
            The Company also prohibits engaging in any of the foregoing activities by using the service of another
            provider, remailer service or otherwise.
          </p>
        </div>
        <h5 className="terms-paragraph-title center">
          SCHEDULE D <br />
          to the General Terms & Conditions
        </h5>
        <h6 className="terms-paragraph-subtitle center">DATA PROTECTION NOTICE</h6>
        <p className="terms-paragraph">
          Your personal data privacy and trust are of crucial importance to us and the success of our business. We
          collect information about our IMAs and here we&apos;ll outline the type of information we gather, the reasons
          we do so, and what we do with it. You&apos;ll also be able to see how you can modify any information you
          entrust to us.
          <br />
          <br />
          We provide network marketing services worldwide and vigorously pursue compliance with every legislation
          applicable to us. The Company is a personal data controller according to Article 3, paragraph 2 from
          Regulation (EU) 2016/679 of both the European Parliament and the Council on 27th April 2016, namely the
          General Data Protection Regulation (GDPR).
          <br />
          <br />
          The current Notice relates to the services offered and to EU IMAs.
          <br />
          <br />
          If you have any questions about your privacy rights, or if you would like to change your privacy preferences,
          you can contact the support (
          <a href="http://support@oneecosystem.eu" target="_blank" rel="noreferrer">
            support@oneecosystem.eu
          </a>
          ). Personal data will only be collected insofar, as the IMA will voluntarily submit them during the ordering
          or registration process. The Company will use these transmitted, personal data (e.g. title, name, address,
          email-address, telephone number, fax number, bank transfer data) without explicit, separate consent in
          accordance with the regulations of the relevant data protection laws and only for the purpose of IMA Agreement
          fulfilling.
          <br />
          <br />
          How long certain personal information is stored depends on the nature of the information we hold and the
          purposes for which they are processed. As a rule, we stop using your personal data for the contractual
          relationship after the termination of the contract with you, but we do not delete the data immediately.
          <br />
          <br />
          For the purpose of fulfilling the IMA Agreement, e.g. for the settlement accounting or payment of bonuses, for
          product or marketing information, the IMA&apos;s personal data will be forwarded to third parties, for example
          the accounting department of an electronic payment platform – to the extent necessary for the fulfillment of
          the aforementioned, contractual obligations. After complete execution of the IMA Agreement, including full
          payment of the agreed remunerations, the IMA’s data will be deleted. Data, which have to be stored for tax or
          commercial reasons, will be blocked after implementation of the IMA Agreement, provided that the IMA has not
          given his/her explicit consent to a further use of his/her personal data.
          <br />
          <br />
          We use a variety of security technologies and procedures to help protect your personal data from unauthorised
          access, use or disclosure. We also take steps to ensure that only persons with appropriate authorisation can
          access your personal data.
          <br />
          <br />
          We have systems and procedures in place to prevent unauthorised access, improper modification or disclosure,
          misuse or loss of information.
          <br />
          <br /> Only staff members who are suitably authorised can access your personal data, if that data is relevant
          to the performance of their duties, whether it be in connection with the providing of services or in
          accordance with legal or regulatory obligations.
          <br />
          <br />
          You can visit our website while remaining anonymous. In each case of a website visit, your Internet browser
          indeed transmits the following data to our web server: date and time of the website visit, the sender&apos;s
          IP address, the requested resource, http-method and http User Agent header. However, our web server will store
          these data separately from other data; hence, it is not possible for us to allocate these data to a specific
          person. Following an anonymous analysis for statistical purposes, these data will be deleted immediately.
          <br />
          <br />
          When your personal data is handled in connection with a service provided by us, you are entitled to rely on a
          number of rights. These rights allow you to exercise meaningful control over the way in which your personal
          data is processed. You may execute any of these rights free of charge (in certain exceptional circumstances a
          reasonable fee may be charged, or Company may refuse to act on the request), and we may ask you to verify your
          identity prior to proceeding with your instruction by way of requesting additional documentation from you.
          Once we are satisfied that we have effectively verified your identity, we will respond to the majority of
          requests without undue delay and within a one-month period. The Company will action your request to have your
          personal data corrected within 10 calendar days.
          <br />
          <br />
          These periods may be extended in exceptional circumstances, and we will inform you where the extended period
          applies to you, along with an explanation of the reasons for the extension.
          <br />
          <br />
          You are entitled to:
          <br />
        </p>
        <ol className="terms-paragraph">
          <li>Access your personal data</li>
          <li>Correct/ restrict /delete your personal data</li>
          <li>Withdraw your consent</li>
          <li>Object to your personal data being used for certain purposes</li>
          <li>Request your personal data to be transferred in electronic form</li>
          <li>Right to complain</li>
        </ol>
        <p className="terms-paragraph">
          <br />
          About cookies.
          <br />
          <br />
          An HTTP cookie, web cookie, browser cookie, or simply, a cookie is a small piece of data that a server sends
          to the visitor&apos;s web browser. It is stored in the user&apos;s device as a simple text file and the
          browser sends it back to the server when requested.
          <br />
          <br />
          Cookies can be read by the website on a user&apos;s subsequent visits. The information stored in a cookie may
          relate to the user&apos;s browsing habits on the web page, or a unique identification number so that the
          website can identify the user on his return visit.
          <br />
          <br />
          Cookies can improve the browsing experience throughout the user&apos;s visit and even on repeat visits by
          saving settings for the various elements of the website.
          <br />
          <br />
          The Company uses Cookies for being able to categorize requests and requirements of the interested party. By
          using cookies, the Company will be enabled to measure the frequency of website visits and the general
          navigation. Cookies are small text files which are stored on your computer system. Please note that some of
          these cookies are being transferred from our server to your computer system - these are usually so-called
          “session-cookies”. &apos;Session-- cookies&apos; are characterised by the fact, that they will be
          automatically deleted from your hard drive at the end of the browser session. Other cookies will remain on
          your computer system, thus enabling us to recognize your computer system during your next visit (so-called
          persistent cookies). You can, of course, reject cookies at any time, as far as your browser so permits. Please
          note, that specific functions of this website may eventually not or only be used with restrictions. This
          happens, if your browser is configured in a way not accepting cookies (of our website).
          <br />
          <br />
          The Company uses Google Analytics for being able to categorize requests and requirements of the interested
          party. Google Analytics applies so-called “cookies”, text files, which will be stored on your computer and
          allow analysing your use of the website. The information created by the cookies referring to your visit of
          this website (including your IP-address) will be transferred to a server of Google in the USA and stored
          there. Google will use this information for analysing your use of the website, for compiling reports on the
          website activities for the website-operators and for providing additional services related to website and
          internet usage. Furthermore, Google may eventually pass this information on to third parties, if this is
          required by law or if third parties will process these data on Google&apos;s behalf. Google will never
          associate your IP-address with other data of Google. You may refuse the installation of cookies by selecting
          the appropriate settings on your browser software; however please note that if doing this way, you may
          eventually not be able to make full use of all the functions of this website. By using this website, you agree
          that the data collected by Google concerning your person may be processed in the manner and for the purpose
          described above.
          <br />
          <br /> The Company uses so called social plugins (“plugins”) from the social network Facebook, which is
          operated by Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA (&apos;Facebook&apos;). The
          plugins are marked with a Facebook logo or by the additional text “social plugin of Facebook” or “Facebook
          social plugin”. Here you can find an overview of the Facebook plugins and their appearance:&nbsp;
          <a href="http://developers.facebook.com/plugins" target="_blank" rel="noreferrer">
            http://developers.facebook.com/plugins
          </a>
          .
          <br />
          <br />
          If you access one site of our web presence containing such a plugin, your browser will establish a direct
          connection to the servers of Facebook. The plugin`s content will be directly transmitted from Facebook to your
          browser, which will integrate it into the website.
          <br />
          <br />
          By integrating the plugin, Facebook receives the information that your browser has accessed the respective
          page of our website, even if you do not have a Facebook account or if you are just not logged in to Facebook.
          This information (including your IP-address) will be directly transferred by your browser to a Facebook server
          in the USA and stored there.
          <br />
          <br />
          If you are logged into Facebook, Facebook can directly allocate the visit to your Facebook account. If you
          interact with the plugins, such as by pressing the &apos;Like&apos; button or by making a comment, the
          corresponding information is also sent directly to a Facebook server and stored there. In addition, the
          information is also posted on Facebook and can be seen by your Facebook friends.
          <br />
          <br />
          Facebook can use this information for the purposes of advertising, market research and appropriate design of
          Facebook pages. To this end, Facebook creates usage, interest and relationship profiles, e.g. for analysing
          your usage of our website concerning superimposed advertisements on Facebook, to inform other Facebook user
          about your activities on our website and for providing other services relating to the use of Facebook.
          <br />
          <br />
          If you do not wish Facebook to allocate the collected data concerning our web presence to your Facebook
          account, you have to log out from Facebook prior to visiting our website.
          <br />
          <br />
          The purpose and scope of data collection, further processing and use of this data by Facebook as well as your
          relevant rights and the setting options aiming at the protection of your privacy can be found under
          Facebook&apos;s data protection policy:&nbsp;
          <a href="http://www.facebook.com/policy.php" target="_blank" rel="noreferrer">
            http://www.facebook.com/policy.php
          </a>
          .
          <br />
          <br />
          Note concerning the Google +1-button: By means of the Google +1-button you can globally publish information.
          Via the Google +1-button, you and other users will receive personalised information from Google and our
          partners. Google will store the information you have given for the content +1 as well as information about the
          site you have watched when clicking +1. Your +1 can be shown as indications together with your profile name
          and your photo in the context of Google services, such as displaying it in search results, in your Google
          profile or at other positions on websites and advertisements on the internet. Google records information about
          your +1-activities thus aiming at the improvement of Google services for you and other users. For being able
          to use the Google +1-button, you will need a globally visible, public Google profile which has to contain at
          least the name chosen for the profile. This name will be used with reference to all Google services. In some
          cases, this name may also replace another name you have been using for sharing content via your Google-
          account. The identity of your Google profile may be shown to users knowing your email-address or having other
          personally identifying information from you.
          <br />
          <br />
          Use of the collected information: In addition to the above-mentioned purposes, the information provided by you
          will be used according to the applicable data protection rules of Google. Google will eventually publish
          summary statistics on the +1-activities of the users or will pass them on to users and partners – such as
          publishers, advertisers or linked websites.
          <br />
          <br />
          On the Company&apos;s sites, functions of the service Twitter are integrated. These functions are offered by
          Twitter Inc., 795 Folsom St., Suite 600, San Francisco, CA 94107, USA. By using Twitter and the “retweet”
          function the visited by your websites will be linked to your Twitter account and communicated to other users.
          While doing so, data will also be transferred to Twitter. Please note that we - in our capacity as provider of
          the sites – are not receiving any information by Twitter about the transferred data&apos;s content and its
          usage. Further information you will find in the Twitter Privacy Statement under&nbsp;
          <a href="http://twitter.com/privacy" target="_blank" rel="noreferrer">
            http://twitter.com/privacy
          </a>
          . Your Twitter data protection settings can be changed in the account settings under &nbsp;
          <a href="http://twitter.com/account/settings " target="_blank" rel="noreferrer">
            http://twitter.com/account/settings
          </a>
          .
          <br />
          <br />
          Disabling individual cookies or types of cookies may require the use of browser add-ons or the use of a
          special type of cookie — an opt-out cookie. Users can also delete all cookies that are already on their
          devices, and they can set most browsers to prevent them from being placed. Disabling cookies globally, through
          the web browser settings, will result in loss of functionality for Company&apos;s website and lead to poor
          user experience. For more information about how to manage cookies, including opting-out of different types of
          cookies, please visit: &nbsp;
          <a href="www.aboutcookies.org." target="_blank" rel="noreferrer">
            www.aboutcookies.org.
          </a>
          <br />
          <br />
          This Data Protection Notice is accessible and retrievable on the Company&apos;s website at any time.
        </p>
      </div>
    </section>
  );
};

export default TermsAndConditions;

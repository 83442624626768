export const header = (theme) => ({
  padding: '4em  4em 0 4em',
  marginBottom: '4em',
  display: 'flex',
  flexDirection: 'column',
  '& .go-back-to-all': {
    display: 'none',
  },
  '& > .header-breadcrumbs': {
    alignSelf: 'center',
  },
  '& > h2': {
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  '@media (max-width: 991px)': {
    padding: '2em  2em 0 2em',
    marginBottom: '3em',
    '& .go-back-to-all': {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '1em',
      '& > p': {
        margin: 0,
        textTransform: 'uppercase',
        color: theme.secondaryDark,
        fontSize: '1.2rem',
      },
      '& > i': {
        marginRight: '0.2em',
        color: theme.secondaryDark,
      },
      '&:hover': {
        '& > p': {
          color: theme.secondary,
        },
        '& > i': {
          color: theme.secondary,
        },
      },
    },
    '& .header-breadcrumbs': {
      display: 'none',
    },
  },
});

export const body = {
  padding: '0 4em',
  marginBottom: '2em',
  '&  .col-item ': {
    marginBottom: '1em',
  },
  '@media (max-width: 991px)': {
    padding: '0 2em',
    '&  > div ': {
      maxWidth: '100%',
    },
  },
};

export const councilBox = (avatar, mobileImage) => (theme) => ({
  border: `1px solid ${theme.primary}`,
  position: 'relative',
  transition: 'backgroundColor 0.4s ease-in-out',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  '& .box-arrow': {
    transition: 'opacity 0.4s ease-in-out',
    width: '1.5rem',
    position: 'absolute',
    top: '0.5em',
    right: '0.5em',
    opacity: 0,
  },
  '& .box-header': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1em',
    '& .avatar-image-wrap': {
      border: `2px solid ${theme.secondaryDarker}`,
      borderRadius: '50%',
      marginBottom: '0.5em',
      position: 'relative',
      overflow: 'hidden',
      '& .avatar-image': {
        transition: 'all 0.4s ease-in-out',
        backgroundColor: theme.primaryDark,
        backgroundImage: `url(${avatar})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '9rem',
        height: '9rem',
        borderRadius: '50%',
        transform: 'scale(1.1)',
      },
      '&:before': {
        transition: 'opacity 0.6s ease-in-out',
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        border: `8px solid ${theme.primaryDark}`,
        borderRadius: '50%',
        opacity: 1,
        zIndex: 1,
      },
    },
    '& > h5': {
      textAlign: 'center',
      marginBottom: '0.2em',
    },
    '& .box-text': {
      fontSize: '0.9rem',
      textTransform: 'uppercase',
      color: theme.secondaryDarker,
      marginBottom: 0,
    },
  },
  '& .box-image': {
    height: 'auto',
    width: '100%',
  },
  '@media (min-width: 768px)': {
    '&:hover': {
      backgroundColor: theme.primaryDark,
      '& .box-arrow': {
        opacity: 1,
      },
      '& .avatar-image-wrap > .avatar-image': {
        transform: 'scale(1.3)',
      },
      '& .avatar-image-wrap:before': {
        opacity: 0,
      },
    },
  },
  '@media (max-width: 767px)': {
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundImage: `url(${mobileImage})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      zIndex: -1,
      opacity: 0.2,
    },
    '& .box-arrow': {
      opacity: 1,
    },
    '& .box-header': {
      '& .avatar-image-wrap': {
        border: 0,
        '& .avatar-image': {
          transform: 'scale(1.25)',
        },
      },
      '& > h5': {
        marginBottom: '0.5em',
      },
    },
    '& .box-image': {
      display: 'none',
    },
    '& .avatar-image-wrap:before': {
      display: 'none',
    },
  },
});

export const footer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '3em 0',
};

import { useState } from 'react';
import { useTranslations } from '@veraio/strank';
import { languageWrap, languageIconContainer, contentContainer, item } from './styles';

const Languages = ({ className }) => {
  const { getText, allEnvLanguages, changeLanguage, language } = useTranslations();
  const [openContainer, setOpenContainer] = useState(false);

  const hideContainer = (event = {}) => {
    event.stopPropagation();
    const { currentTarget, relatedTarget } = event;
    if (currentTarget.contains(relatedTarget)) return;
    setOpenContainer(false);
  };

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={() => !openContainer && setOpenContainer(true)}
      onBlur={hideContainer}
      css={languageWrap}>
      <div role="button" tabIndex={0} onClick={(event) => event.preventDefault()} css={languageIconContainer}>
        <span>{language?.code?.toLocaleUpperCase()}</span>
      </div>

      {openContainer && (
        <div css={contentContainer} {...(className && { className })}>
          {allEnvLanguages?.map((el, ind) => {
            return (
              <div
                role="button"
                tabIndex={0}
                key={ind}
                onClick={() => {
                  changeLanguage(el?.code);
                  setOpenContainer(false);
                }}
                css={item(el.name === language.name)}>
                <p className="name">
                  {el?.code} ({getText(`${el?.name}`)})
                </p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Languages;

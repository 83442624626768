export const calendarControlWrap = (theme) => ({
  display: 'flex',
  padding: '1em 0.5em',
  alignItems: 'center',
  justifyContent: 'center',
  border: `1px solid ${theme.primary}`,
  '& > .calendar-button': {
    '& > svg': {
      width: '0.8rem',
      '& > path': {
        stroke: theme.secondaryDarker,
      },
    },
    '&:hover': {
      '& > svg > path': {
        stroke: theme.secondary,
      },
    },
  },

  '& > .calendar-month': {
    fontWeight: '400',
    marginLeft: '1em',
    marginRight: '1em',
  },
});

export const calendarWrap = {
  display: 'grid',
  gridTemplateColumns: 'repeat(7, 1fr)',
  borderTopLeftRadius: 4,
  borderTopRightRadius: 4,
  overflow: 'hidden',
  marginBottom: '6em',
  '@media (max-width: 767px)': {
    display: 'block',
  },
};

export const weekdayBox = (theme) => ({
  backgroundColor: theme.secondaryDark,
  padding: '0.5em',
  '& .calendar-box-weekday': {
    color: theme.primary,
    fontSize: '1.2rem',
    textTransform: 'uppercase',
  },
  '@media (max-width: 767px)': {
    display: 'none',
  },
});

export const calendarBox = (hasDate, currentDate) => (theme) => ({
  aspectRatio: '1',
  overflowX: 'hidden',
  overflowY: 'auto',
  border: `1px solid ${hasDate ? theme.secondaryDarker : theme.primary}`,
  position: 'relative',
  padding: '0 0.4em',
  '& .calendar-box-date': {
    position: 'sticky',
    top: 0,
    display: 'inline-block',
    color: currentDate ? theme.white : theme.secondaryDark,
    backgroundColor: currentDate ? theme.primary : theme.primaryDarker,
    padding: currentDate ? '0 0.5em' : 0,
    fontSize: '1.2rem',
    fontWeight: '500',
    '& .calendar-box-weekday': {
      display: 'none',
    },
  },
  '::-webkit-scrollbar': {
    width: '2px',
  },
  '::-webkit-scrollbar-track': {
    borderRadius: '10px',
    backgroundColor: theme.darkOverlay,
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: theme.secondary,
    borderRadius: '10px',
  },
  '::-webkit-scrollbar-thumb:hover': {
    display: 'none',
  },
  '@media (max-width: 767px)': {
    aspectRatio: '0',
    display: hasDate ? 'block' : 'none',
    borderBottom: 0,
    padding: '0.5em',
    '&:last-of-type': {
      borderBottom: `1px solid ${theme.secondaryDarker}`,
    },
    '& .calendar-box-date': {
      '& .calendar-box-weekday': {
        display: 'flex',
        color: theme.secondary,
        fontSize: '0.9rem',
      },
    },
  },
});

export const calendarEvent = (isBefore) => (theme) => ({
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  lineClamp: 2,
  WebkitBoxOrient: 'vertical',
  backgroundColor: isBefore ? theme.primaryDark : theme.secondaryDarker,
  color: isBefore ? theme.secondaryDarker : theme.primaryDark,
  marginBottom: '0.2em',
  padding: '0.2em',
  '& .event-title': {
    fontSize: '0.7rem',
    margin: 0,
  },
  '@media (max-width: 767px)': {
    marginTop: '0.8em',
    '& .event-title': {
      fontSize: '0.9rem',
    },
  },
});

export const wrap = (theme) => ({
  display: 'inline-flex',
  backgroundColor: theme.primary,
  padding: '0.2em 0.3em',
  borderRadius: 3,
  marginBottom: '1em',
  '& > p': {
    lineHeight: 1,
    margin: 0,
    textTransform: 'uppercase',
    fontSize: '0.7rem',
    color: theme.white,
  },
});

import { useEffect } from 'react';
import { termsAndPolicyContainer } from './styles';

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div css={termsAndPolicyContainer}>
      <div className="paper-box">
        <h4 className="terms-paragraph-title">Privacy Policy</h4>
        <p className="terms-paragraph">
          This Privacy Policy, along with our Terms and Conditions and IMA Agreement, governs One Ecosystem’s
          collection, processing, and use of your personal information.
        </p>
        <p className="terms-paragraph">
          By accessing One Ecosystem’s website or any related websites, including those hosted by our partners, all
          individuals, including Independent Marketing Associates (IMAs), agree to be bound by this Privacy Policy
          unless expressly stated otherwise in writing. One Ecosystem is committed to maintaining the highest level of
          protection for your information. Your personal data will only be used in accordance with this policy.
        </p>
        <p className="terms-paragraph">
          One Ecosystem’s objective is to enhance users’ trust by implementing the necessary measures to safeguard your
          information. We take the protection of your data very seriously and comply with all applicable data protection
          laws. This policy outlines how we process personal information online.
        </p>
        <p className="terms-paragraph">
          Personal Information refers to details that identify an individual, such as names, addresses, email addresses,
          transaction details, and banking information. This does not include anonymized or aggregated data that cannot
          identify a specific user.
        </p>
        <p className="terms-paragraph">
          In compliance with applicable laws and regulations, including those related to personal data, One Ecosystem
          must verify the identity of participants when they register on the portal (portal.oneecosystem.eu). Various
          means, as permitted by law, will be used for this verification.
        </p>
        <h4 className="terms-paragraph-title">Collection of Personal Information</h4>
        <p className="terms-paragraph">
          To register as an Independent Marketing Associate (IMA) and access your account(s), you will need to sign up
          with an email and password. Once registered, you will access your profile using these credentials. It’s
          crucial that you choose a strong password and keep it confidential.
        </p>
        <p className="terms-paragraph">
          During registration, we will ask for personal information such as your email address, name, country, telephone
          number, and physical address. We may also collect demographic data, such as postal code, age, and gender. To
          ensure your information is secure, we use Secure Sockets Layer (SSL) technology, which encrypts your data
          during transmission.
        </p>
        <p className="terms-paragraph">
          Additionally, we may collect information about your activities on our website, including the pages you view,
          the links you click, and other actions related to our services. This includes standard information sent by
          your browser, such as IP address, browser type, language, access times, and referring websites.
        </p>
        <p className="terms-paragraph">
          When you receive communications from One Ecosystem, we may use technologies to track email openings and link
          clicks. This helps us tailor future communications to your preferences.
        </p>
        <p className="terms-paragraph">
          We aim to provide a consistent and personalized experience by combining information collected through our
          system and related websites hosted by our partners.
        </p>
        <h4 className="terms-paragraph-title">Personal Data for User Account Operations</h4>
        <p className="terms-paragraph">
          To manage account creation, withdrawals, and payments, your personal data will be processed in compliance with
          current legislation. Before your first payment authorization, we will request a copy of an official identity
          document (passport, ID card, or driver’s license) and proof of address, in line with our KYC (Know Your
          Customer) policies.
        </p>
        <h4 className="terms-paragraph-title">Data Processing for Analysis, Advertising, and Inquiries</h4>
        <p className="terms-paragraph">
          When you contact us, we will process any personal details you choose to share in relation to your inquiry. If
          you provide sensitive information, such as health data, we will only use this information to deliver the
          requested services and address your specific needs or inquiries.
        </p>
        <p className="terms-paragraph">
          We may also process your personal data for statistical analysis, quality improvements, or direct marketing
          (with your consent). If you have opted in, we will send you information about our products and services.
        </p>
        <h4 className="terms-paragraph-title">Data Subject Rights and Obligations</h4>
        <p className="terms-paragraph">
          By agreeing to One Ecosystem’s Terms and Conditions and based on Article 4, paragraph 11 of the GDPR, you
          consent to the processing of your personal data for the following purposes:
        </p>
        <ol className="terms-paragraph">
          <li className="dashed">Identity verification</li>
          <li className="dashed">Account/profile verification</li>
          <li className="dashed">Verification of legal entity representation (if applicable)</li>
          <li className="dashed">Compliance with AML/CFT regulations</li>
          <li className="dashed">Marketing (with your consent)</li>
        </ol>
        <p className="terms-paragraph">
          You can request the following forms by contacting us at
          <a href="mailto:compliance@oneecosystem.eu">compliance@oneecosystem.eu</a>:
        </p>
        <ol type="1" className="terms-paragraph">
          <li>Data Subject Withdrawal Form</li>
          <li>Data Subject Access Request Form</li>
          <li>Data Subject Disclosure Form</li>
        </ol>
        <h4 className="terms-paragraph-title">Right to Be Forgotten</h4>
        <p className="terms-paragraph">
          Please note that while you may request the deletion of your personal records and account, your KYC (Know Your
          Customer) information will still be securely stored. Even after your profile is deleted, your account will be
          unlinked from your main profile, and the Network will no longer have access to your personal information.
          However, the company will retain this information in a confidential manner.
        </p>
        <p className="terms-paragraph">
          According to Article 17 of the GDPR, the &quot;Right to Erasure&quot; (or &quot;Right to be Forgotten&quot;)
          allows the company to store KYC and other personal information under Section 3(e), which permits retention for
          the establishment, exercise, or defense of legal claims.
        </p>
        <p className="terms-paragraph">
          Please be informed that this process is permanent and irreversible. If you request the deletion of your
          profile login information, you will lose all access to the One Ecosystem, including but not limited to the
          Portal, DealShaker, and the Academy.
        </p>
        <p className="terms-paragraph">
          If you wish to proceed with the deletion of your personal data, follow these steps:
        </p>
        <ol type="1" className="terms-paragraph">
          <li>
            Download the Word document <b>Art. 17 GDPR Right to erasure</b> from portal.oneecosystem.eu and replace the
            red text with your personal information.
          </li>
          <li>Print the document and sign it at the bottom next to your name.</li>
          <li>
            Take a selfie while holding the signed document and your passport (or any other official document that
            includes your signature).
          </li>
          <li>
            Email the selfie and a scanned copy of the signed document to
            <a href="mailto:compliance@oneecosystem.eu">compliance@oneecosystem.eu</a>. Make sure to send it from the
            email address registered to your One Ecosystem profile.
          </li>
        </ol>
        <h4 className="terms-paragraph-title">Disclosure of Personal Information</h4>
        <p className="terms-paragraph">
          We may share personal information with government authorities when required by law and with trusted partners
          for services such as technical support, call center management, or helpdesk platforms. We ensure that all
          partners adhere to strict information security standards.
        </p>
        <p className="terms-paragraph">
          Your personal information may also be shared with third parties in non-EU/EEA countries, where we will ensure
          that appropriate protection measures are in place, comparable to EU/EEA standards.
        </p>
        <h4 className="terms-paragraph-title">How We Protect Your Privacy</h4>
        <p className="terms-paragraph">
          We process your personal data only for the purposes outlined in this policy. Access to data is restricted to
          trained personnel who follow strict confidentiality guidelines. We also use encryption to protect sensitive
          data, including login credentials.
        </p>
        <p className="terms-paragraph">
          If you suspect unauthorized access to your account, please change your password immediately and contact us at
          <a href="mailto:compliance@oneecosystem.eu">compliance@OneEcosystem.eu</a>
        </p>
        <h4 className="terms-paragraph-title">Changes to This Policy</h4>
        <p className="terms-paragraph">
          Any changes to this policy will be announced on our website. In the event of significant updates, we will
          notify you by email.
        </p>
        <p className="terms-paragraph">Date: 26.09.2024</p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;

/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import hash from 'object-hash';
import { isEmpty } from 'lodash-es';
import {
  PopperBox,
  PopperEventBox,
  EventBottomSheet,
  EventsFilterBar,
  useWindowSize,
  useUrlParams,
  showError,
} from 'components';
import { getEvents } from 'services';
import { filterModel } from 'utils';
import { ReactComponent as ChevronLeft } from 'assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';
import { calendarControlWrap, weekdayBox, calendarWrap, calendarBox, calendarEvent } from './styles';

const dateFormat = 'YYYY-MM-DD';

const WEEKDAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const Calendar = () => {
  const windowWidth = useWindowSize(window.innerWidth, window.innerHeight);
  const { urlQueryParams } = useUrlParams();
  const [calendar, setCalendar] = useState();
  const today = moment().format(dateFormat);
  const [date, setDate] = useState(today);
  const [isMobile, setIsMobile] = useState();
  const bottomSheetRef = useRef();

  useEffect(() => {
    if (!isEmpty(urlQueryParams)) {
      const firstDate = moment(date).startOf('month').format(dateFormat);
      const lastDate = moment(date).endOf('month').format(dateFormat);
      getCalendar(firstDate, lastDate);
    }
  }, [hash({ urlQueryParams })]);

  useEffect(() => {
    setIsMobile(windowWidth.width <= 767);
  }, [windowWidth.width]);

  const geEventsArray = (event) => {
    const startDateMoment = moment(event?.startDate);

    const daysDiff = moment(event?.endDate).diff(startDateMoment, 'days');

    if (daysDiff === 0) return [startDateMoment.format(dateFormat)];

    return [...Array(daysDiff + 1)].map((_, index) =>
      index === 0 ? startDateMoment.format(dateFormat) : startDateMoment.add(1, 'day').format(dateFormat),
    );
  };

  const fetchEvents = async (firstDate, lastDate) => {
    const { countryId, isCorporate, isIma } = urlQueryParams;
    const endDate = moment(lastDate, dateFormat).add(1, 'days');

    const filter = filterModel({
      ...(countryId && { countryId: `countryId='${countryId}'` }),
      ...(isCorporate && !isIma && { createdBy: "createdBy='OneEcosystem'" }),
      ...(isIma && !isCorporate && { createdBy: "createdBy!='OneEcosystem'" }),
      all: `isActive=true && (startDate>='${firstDate}' && endDate<='${endDate}' || endDate>='${firstDate}' && startDate<='${endDate}')`,
    });

    const [res, err] = await getEvents({
      perPage: 200,
      filter,
      fields: 'id,collectionId,image,title,createdBy,description,startDate,endDate',
      skipTotal: true,
    });

    if (err) return showError(err?.data);

    const formatEvents = res?.items?.reduce((acc, event) => {
      const eventArray = geEventsArray(event);
      eventArray.forEach((element) => {
        const dateKey = moment(element).format(dateFormat);
        if (!acc[dateKey]) acc[dateKey] = [];
        acc[dateKey].push(event);
      });
      return acc;
    }, {});

    return formatEvents;
  };

  const getCalendar = async (
    firstDate = moment().startOf('month').format(dateFormat),
    lastDate = moment().endOf('month').format(dateFormat),
  ) => {
    const events = await fetchEvents(firstDate, lastDate);

    const daysDiff = moment(lastDate).diff(firstDate, 'days');

    const datesArray = [...Array(daysDiff + 1)].map((_, index) => {
      const firstDateFormat = moment(firstDate).format(dateFormat);
      const otherDatesFormat = moment(firstDate).add(index, 'day').format(dateFormat);

      return index === 0
        ? {
            date: firstDateFormat,
            events: events[firstDateFormat] || [],
          }
        : {
            date: otherDatesFormat,
            events: events[otherDatesFormat] || [],
          };
    });

    const startIndex = WEEKDAYS.indexOf(moment(firstDate).format('ddd'), 1);
    const startArray = [...Array(startIndex)].map(() => null);

    setCalendar([...startArray, ...datesArray]);
  };

  const handleMonthChange = (data, isNext) => {
    const monthToSet = isNext ? moment(data).add(1, 'month') : moment(data).subtract(1, 'month');

    const monthStartDate = monthToSet.startOf('month').format(dateFormat);
    const monthEndDate = monthToSet.endOf('month').format(dateFormat);

    setDate(monthToSet.format(dateFormat));
    getCalendar(monthStartDate, monthEndDate);
  };

  const handleBottomSheet = (data) => {
    bottomSheetRef.current?.open(data);
  };

  return (
    <>
      <section>
        <div css={calendarControlWrap}>
          <div role="button" tabIndex={0} className="calendar-button" onClick={() => handleMonthChange(date)}>
            <ChevronLeft />
          </div>
          <h5 className="calendar-month">{moment(date).format('MMM YYYY')}</h5>
          <div role="button" tabIndex={0} className="calendar-button" onClick={() => handleMonthChange(date, true)}>
            <ChevronRight />
          </div>
        </div>
        <EventsFilterBar />
        <div css={calendarWrap}>
          {WEEKDAYS?.map((el, i) => (
            <div css={weekdayBox} key={i}>
              <span className="calendar-box-weekday">{el}</span>
            </div>
          ))}
          {calendar?.map((el, i) => {
            const isToday = moment(el?.date).isSame(today);
            return (
              <div css={calendarBox(el?.date, isToday)} key={i}>
                {el?.date && (
                  <span className="calendar-box-date">
                    <span className="calendar-box-weekday">{WEEKDAYS?.at(moment(el?.date).weekday() - 1)} </span>
                    {moment(el?.date).format('DD')}
                  </span>
                )}
                {el?.events?.map((event) => (
                  <div key={event?.id}>
                    {isMobile ? (
                      <div
                        role="button"
                        tabIndex={0}
                        onClick={() => handleBottomSheet(event)}
                        css={calendarEvent(moment(event?.startDate).isBefore())}>
                        <p className="event-title">{event?.title}</p>
                      </div>
                    ) : (
                      <PopperBox key={event?.id} content={<PopperEventBox data={event} />}>
                        <div css={calendarEvent(moment(event?.startDate).isBefore())}>
                          <p className="event-title">{event?.title}</p>
                        </div>
                      </PopperBox>
                    )}
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      </section>
      <EventBottomSheet ref={bottomSheetRef} />
    </>
  );
};

export default Calendar;

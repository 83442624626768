import { css } from '@emotion/react';

export default (theme) => css`
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

  :root {
    font-size: 16px;
    --rsbs-backdrop-bg: rgba(0, 0, 0, 0.6);
    --rsbs-bg: ${theme.primaryDark};
    --rsbs-handle-bg: hsla(0, 0%, 0%, 0.14);
    --rsbs-max-w: auto;
    --rsbs-ml: env(safe-area-inset-left);
    --rsbs-mr: env(safe-area-inset-right);
  }

  [data-rsbs-scroll='true'] {
    overflow-x: visible;
    ::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: ${theme.darkOverlay};
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: ${theme.secondaryDarker};
    }

    ::-webkit-scrollbar-thumb:hover {
      display: none;
    }
  }

  html {
    font-size: 16px;
    letter-spacing: 0.4px;
  }
  body {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-optical-sizing: auto;
    font-weight: 400;
    color: ${theme.secondaryDark};
    background-color: ${theme.primaryDarker};
    overflow-x: hidden;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  h7 {
    font-weight: 700;
    color: ${theme.secondary};
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  h1 {
    font-size: 4.5rem;
    line-height: 1.2;
    margin: 0;
    padding: 0;
  }

  h2 {
    font-weight: 600;
    font-size: 3.5rem;
    line-height: 1.2;
    margin: 0;
    padding: 0;
  }

  h3 {
    font-weight: 600;
    font-size: 2.5rem;
    line-height: 1.2;
    margin: 0;
    padding: 0;
  }

  h4 {
    font-weight: 600;
    font-size: 2rem;
    line-height: 1.2;
    margin: 0;
    padding: 0;
  }

  h5 {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.2;
    margin: 0;
    padding: 0;
  }

  h6 {
    font-weight: 600;
    font-size: 1.1rem;
    line-height: 1.2;
    margin: 0;
    padding: 0;
  }

  p {
    font-size: 1rem;
    line-height: 1.4;
    margin-bottom: 0.75em;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  strong {
    font-family: 600;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
  .justifyText {
    text-align: justify;
  }

  .container-fluid {
    padding: 0 4em;
  }

  @media only screen and (max-width: 1199px) {
    h1 {
      font-size: 3.5rem;
    }
    h2 {
      font-size: 2.5rem;
    }
    h3 {
      font-size: 2rem;
    }
    h4 {
      font-size: 1.51rem;
    }
  }

  @media only screen and (max-width: 991px) {
    :root {
      font-size: 13px;
    }
  }

  @media only screen and (max-width: 767px) {
    .container-fluid {
      padding: 0 2em;
    }
  }
  .slick-list,
  .slick-slider,
  .slick-track {
    position: relative;
    display: block;
  }
  .slick-loading .slick-slide,
  .slick-loading .slick-track {
    visibility: hidden;
  }
  .slick-slider {
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }
  .slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0;
    padding-bottom: 32px;
  }
  .slick-list:focus {
    outline: 0;
  }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }
  .slick-slider .slick-list,
  .slick-slider .slick-track {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .slick-track {
    top: 0;
    left: 0;
  }
  .slick-track:after,
  .slick-track:before {
    display: table;
    content: '';
  }
  .slick-track:after {
    clear: both;
  }
  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
  }
  [dir='rtl'] .slick-slide {
    float: right;
  }
  .slick-slide img {
    display: block;
  }
  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
    display: none;
  }
  .slick-dots,
  .slick-next,
  .slick-prev {
    position: absolute;
    display: block;
    padding: 0;
  }
  .slick-dots li button:before,
  .slick-next:before,
  .slick-prev:before {
    font-family: slick;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .slick-loading .slick-list {
    background: url(ajax-loader.gif) center center no-repeat #fff;
  }
  @font-face {
    font-family: slick;
    font-weight: 400;
    font-style: normal;
    src: url(fonts/slick.eot);
    src: url(fonts/slick.eot?#iefix) format('embedded-opentype'), url(fonts/slick.woff) format('woff'),
      url(fonts/slick.ttf) format('truetype'), url(fonts/slick.svg#slick) format('svg');
  }
  .slick-next,
  .slick-prev {
    font-size: 0;
    line-height: 0;
    top: 50%;
    width: 20px;
    height: 20px;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: 0;
    background: 0 0;
  }
  .slick-next:focus,
  .slick-next:hover,
  .slick-prev:focus,
  .slick-prev:hover {
    color: transparent;
    outline: 0;
    background: 0 0;
  }
  .slick-next:focus:before,
  .slick-next:hover:before,
  .slick-prev:focus:before,
  .slick-prev:hover:before {
    opacity: 1;
  }
  .slick-next.slick-disabled:before,
  .slick-prev.slick-disabled:before {
    opacity: 0.25;
  }
  .slick-next:before,
  .slick-prev:before {
    font-size: 20px;
    line-height: 1;
    opacity: 0.75;
    color: #fff;
  }
  .slick-prev {
    left: -25px;
  }
  [dir='rtl'] .slick-prev {
    right: -25px;
    left: auto;
  }
  .slick-prev:before {
    content: '←';
  }
  .slick-next:before,
  [dir='rtl'] .slick-prev:before {
    content: '→';
  }
  .slick-next {
    right: -25px;
  }
  [dir='rtl'] .slick-next {
    right: auto;
    left: -25px;
  }
  [dir='rtl'] .slick-next:before {
    content: '←';
  }
  .slick-dotted.slick-slider {
    margin-bottom: 30px;
  }
  .slick-dots {
    bottom: 0px;
    width: 100%;
    margin: 0;
    list-style: none;
    text-align: center;
  }
  .slick-dots li {
    position: relative;
    display: inline-block;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
  }
  .slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    border: 0;
    outline: 0;
    border-radius: 20px;
    background-color: transparent;
    border: 2px solid ${theme.primary};
  }
  .slick-dots li button:focus,
  .slick-dots li button:hover {
    outline: 0;
  }
  .slick-dots li.slick-active button {
    background-color: ${theme.primary};
  }
`;

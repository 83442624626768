export const footerButton = {
  width: '100%',
};

export const header = (theme) => ({
  display: 'flex',
  justifyContent: 'space-between',
  '& > h4': {
    fontSize: '1.5rem',
    fontWeight: 500,
    color: theme.secondaryDark,
  },
});

export const wrap = (theme) => ({
  padding: '1em',
  '& > .content-header': {
    marginBottom: '1em',
    paddingBottom: '2em',
    borderBottom: `1px solid ${theme.border}`,
  },
  '& > .content-body': {
    '& > .event-title': {
      fontSize: '1.2rem',
      marginBottom: '0.5em',
    },
    '& > .event-text': {
      marginBottom: '1.5em',
    },
  },
});

export const eventImage = (url) => (theme) => ({
  backgroundColor: theme.primaryDark,
  backgroundImage: `url(${url})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  aspectRatio: ' 16 / 9',
  width: '100%',
});

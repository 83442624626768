import { useState, useEffect } from 'react';
import { useTranslations } from '@veraio/strank';
import { Link } from 'react-router-dom';
import { isNil } from 'lodash-es';
import { Row, Col, Breadcrumbs, Button, Icon, showError } from 'components';
import { getGlobalCouncils } from 'services';
import { getImagePath } from 'utils';
import { ReactComponent as GoToArrow } from 'assets/icons/go-to-icon.svg';
import { header, body, councilBox, footer } from './styles';

const GlobalCouncil = () => {
  const { getText } = useTranslations();
  const [councils, setCouncils] = useState(null);

  const items = [
    {
      label: getText('networkStructure'),
      url: '/network',
    },
    {
      label: getText('globalCouncil'),
      uppercase: true,
    },
  ];

  useEffect(() => {
    fetchGlobalCouncils();
  }, []);

  const fetchGlobalCouncils = async (page) => {
    const [res, err] = await getGlobalCouncils({
      page,
      perPage: 50,
      filter: '(isActive=true)',
      expand: 'regionId',
      fields: 'id,collectionId,image,name,expand',
    });
    if (err) return showError(err?.data);

    setCouncils((prev) =>
      isNil(prev) || page === 1
        ? res
        : {
            ...res,
            items: [...prev.items, ...res.items],
          },
    );
  };

  const handleMore = () => {
    const nextPage = councils?.page + 1;
    fetchGlobalCouncils(nextPage);
  };

  return (
    <>
      <section css={header}>
        <Breadcrumbs items={items} className="header-breadcrumbs" />
        <Link to="/network">
          <div className="go-back-to-all">
            <Icon iconName="la la-arrow-left" />
            <p>{getText('networkStructure')}</p>
          </div>
        </Link>
        <h2>{getText('internationalGlobalCouncil')}</h2>
      </section>
      <div css={body}>
        <Row container>
          {councils?.items?.map((el) => (
            <Col key={el?.id} md={6} lg={4} xl={3} className="col-item">
              <Link
                to={`/network/member/${el?.id}`}
                css={councilBox(getImagePath(el), getImagePath(el?.expand?.regionId, true))}>
                <GoToArrow className="box-arrow" />
                <div className="box-header">
                  <div className="avatar-image-wrap">
                    <div className="avatar-image" />
                  </div>
                  <h5>{el?.name}</h5>
                  <p className="box-text">{el?.expand?.regionId?.name}</p>
                </div>
                <img src={getImagePath(el?.expand?.regionId)} alt={`${el?.region?.name}-img`} className="box-image" />
              </Link>
            </Col>
          ))}
        </Row>
      </div>
      <div css={footer}>
        {councils?.page < councils?.totalPages && (
          <Button onClick={handleMore} type="gray">
            {getText('loadMore')}
          </Button>
        )}
      </div>
    </>
  );
};

export default GlobalCouncil;

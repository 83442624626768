import { useState, forwardRef, useImperativeHandle } from 'react';
import { useTranslations } from '@veraio/strank';
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import { Button, Icon, EventTypeBadge } from 'components';
import { getImagePath, getTranslationData } from 'utils';
import { header, wrap, footerButton, eventImage } from './styles';

const EventBottomSheet = forwardRef((_, ref) => {
  const { getText, language } = useTranslations();
  const [show, setShow] = useState(false);
  const [event, setEvent] = useState();

  useImperativeHandle(ref, () => ({
    open: (data) => {
      setEvent(data);
      setShow(true);
    },
    close: () => setShow(false),
  }));

  return (
    <BottomSheet
      onDismiss={() => setShow(false)}
      open={show}
      defaultSnap={({ maxHeight }) => maxHeight / 1.25}
      snapPoints={({ maxHeight }) => [maxHeight / 1.25]}
      header={
        <div css={header}>
          <h4>{getText('events')}</h4>
          <Icon onClick={() => setShow(false)} iconName="las la-times" />
        </div>
      }
      footer={
        <Button css={footerButton} type="secondary" linkTo={`/events/${event?.id}`}>
          {getText('viewEvent')}
        </Button>
      }>
      <div css={wrap}>
        <div className="content-header">
          <div css={eventImage(getImagePath(event))} />
        </div>
        <div className="content-body">
          <EventTypeBadge createdBy={event?.createdBy} />
          <h6 className="event-title">{getTranslationData(event, 'title', language?.code)}</h6>
          <p className="event-text">{getTranslationData(event, 'description', language?.code)}</p>
        </div>
      </div>
    </BottomSheet>
  );
});

export default EventBottomSheet;

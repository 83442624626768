import { useTranslations } from '@veraio/strank';
import { Row, Col, Calendar } from 'components';
import { header } from './styles';

const EventsCalendar = () => {
  const { getText } = useTranslations();
  return (
    <Row container>
      <Col>
        <section css={header}>
          <h2>{getText('oneEcosystemEvents')}</h2>
          <p>{getText('eventsText')}</p>
        </section>
        <Calendar />
      </Col>
    </Row>
  );
};

export default EventsCalendar;

export const header = (theme) => ({
  padding: '4em  4em 0 4em',
  marginBottom: '4em',
  display: 'flex',
  flexDirection: 'column',
  '& .go-back-to-all': {
    display: 'none',
  },
  '& > .header-breadcrumbs': {
    alignSelf: 'center',
  },
  '& > h2': {
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  '@media (max-width: 991px)': {
    padding: '2em  2em 0 2em',
    marginBottom: '3em',
    '&  > div ': {
      maxWidth: '100%',
    },
    '& .go-back-to-all': {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '1em',
      '& > p': {
        margin: 0,
        textTransform: 'uppercase',
        color: theme.secondaryDark,
        fontSize: '1.2rem',
      },
      '& > i': {
        marginRight: '0.2em',
        color: theme.secondaryDark,
      },
      '&:hover': {
        '& > p': {
          color: theme.secondary,
        },
        '& > i': {
          color: theme.secondary,
        },
      },
    },
    '& .header-breadcrumbs': {
      display: 'none',
    },
  },
});

export const body = (theme) => ({
  padding: '0 4em',
  marginBottom: '2em',
  '&  .country-list-header ': {
    borderTop: `1px solid ${theme.primary}`,
    padding: '2em 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&  .country-list-title ': {
      textTransform: 'uppercase',
      color: theme.secondaryDarker,
    },
    '&  .country-regions-list ': {
      display: 'flex',
      alignItems: 'center',
      overflowX: 'scroll',
      scrollBehavior: 'smooth',
      '::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
  '&  .col-item ': {
    marginBottom: '1em',
  },
  '@media (max-width: 991px)': {
    padding: '0 2em',
    '&  > div ': {
      maxWidth: '100%',
    },
    '@media (max-width: 767px)': {
      '&  .country-list-header ': {
        display: 'block',
        '&  .country-list-title ': {
          marginBottom: '1em',
        },
      },
    },
  },
});

export const countryListItem = (isActive) => (theme) => ({
  fontSize: '1rem',
  borderBottom: `1px solid ${isActive ? theme.secondaryDarker : theme.transparent}`,
  color: isActive ? theme.secondaryDark : theme.secondaryDarker,
  padding: '0.2em 0.5em 0.5em 0.5em',
  margin: '0 0.2em',
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
});

export const countryBox = (image) => (theme) => ({
  border: `1px solid ${theme.primary}`,
  display: 'block',
  transition: 'border 0.4s ease-in-out',
  marginBottom: '2em',
  '& .image-wrap': {
    overflow: 'hidden',
    '& .image': {
      transition: 'all 0.4s ease-in-out',
      backgroundImage: `url(${image})`,
      backgroundPosition: 'center',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      height: '15rem',
      width: '100%',
    },
  },
  '& .text-wrap': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: `1px solid ${theme.primary}`,
    padding: '1em',
    '& > h5': {
      fontSize: '1rem',
      color: theme.secondaryDarker,
      transition: 'color 0.4s ease-in-out',
    },
    '& > i': {
      color: theme.secondaryDark,
    },
  },

  '@media (min-width: 768px)': {
    '& .text-wrap': {
      '& > i': {
        transition: 'opacity 0.4s ease-in-out',
        opacity: 0,
      },
    },
    '&:hover': {
      border: `1px solid ${theme.secondaryDarker}`,
      '& .image-wrap > .image': {
        transform: 'scale(1.1)',
      },
      '& .text-wrap': {
        '& > h5': {
          color: theme.secondaryDark,
        },
        '& > i': {
          opacity: 1,
        },
      },
    },
  },
});

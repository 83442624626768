export const wrap = (theme) => ({
  width: '20rem',
  backgroundColor: theme.primaryDarker,
  border: `1px solid ${theme.border}`,
  '& > .p-box-header': {
    padding: '1em',
    borderBottom: `1px solid ${theme.border}`,
  },
  '& > .p-box-body': {
    padding: '1em',
    '& > .event-title': {
      fontSize: '0.9rem',
      marginBottom: '0.5em',
    },
    '& > .event-text': {
      fontSize: '0.8rem',
      marginBottom: '1.5em',
    },
    '& > .event-button': {
      width: '100%',
    },
  },
});

export const eventImage = (url) => (theme) => ({
  backgroundColor: theme.primaryDark,
  backgroundImage: `url(${url})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  aspectRatio: ' 16 / 9',
  width: '100%',
});

import { Link } from 'react-router-dom';
import { useTranslations } from '@veraio/strank';
import { footerContainer } from './styles';

const Footer = () => {
  const getYear = new Date().getFullYear();
  const { getText } = useTranslations();

  return (
    <div css={footerContainer}>
      <div className="all-rights">
        <p>
          © {getYear} {getText('allRightsReserved')}.
        </p>
      </div>
      <div className="footer-links">
        <Link to="/terms-and-conditions">{getText('termsConditions')}</Link>
      </div>
      <div className="footer-links">
        <Link to="/privacy-policy"> {getText('privacyPolicy')}</Link>
      </div>
    </div>
  );
};

export default Footer;

/* eslint-disable no-unused-vars */
import React, { useEffect, useRef } from 'react';
import { useTranslations } from '@veraio/strank';
import { CheckButton, Row, Col, CountriesSelect, useUrlParams, useIsMounted } from 'components';
import { wrap } from './styles';

const EventsFilterBar = () => {
  const { urlQueryParams, setUrlQueryParams } = useUrlParams();
  const { getText } = useTranslations();
  const { countryId, isCorporate, isIma } = urlQueryParams;
  const queryParams = useRef(urlQueryParams);
  const isMounted = useIsMounted();

  useEffect(() => {
    if (isMounted.current) {
      queryParams.current = {
        ...queryParams.current,
        ...(!queryParams?.current?.isCorporate && { isCorporate: true }),
        ...(!queryParams?.current?.isIma && { isIma: true }),
      };
      setUrlQueryParams(queryParams.current);
    }
  }, []);

  const handleChangeCorporate = (val) => {
    if (!val && !isIma) handleChangeIma(true);
    queryParams.current = { ...queryParams.current, isCorporate: val };
    setUrlQueryParams(queryParams.current);
  };
  const handleChangeIma = (val) => {
    if (!val && !isCorporate) handleChangeCorporate(true);
    queryParams.current = { ...queryParams.current, isIma: val };
    setUrlQueryParams(queryParams.current);
  };

  const handleChangeCountryId = (val) => {
    queryParams.current = { ...queryParams.current, countryId: val?.id };
    setUrlQueryParams(queryParams.current);
  };

  return (
    <div css={wrap}>
      <div className="left-side">
        <CountriesSelect value={countryId} onChange={handleChangeCountryId} />
      </div>
      <div className="right-side">
        <Row horizontalGap={8}>
          <Col md="auto">
            <CheckButton onChange={handleChangeIma} value={isIma} label={getText('imaEvent')} />
          </Col>
          <Col md="auto">
            <CheckButton onChange={handleChangeCorporate} value={isCorporate} label={getText('corporateEvent')} />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default EventsFilterBar;

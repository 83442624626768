import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import Input from '../Input';
import useOptions from './useOptions';
import { dropdownSearch } from './styles';

const OptionsList = (props) => {
  const { withSearch, className, containerRef } = props;

  const {
    renderList,
    filterResults,
    searchRef,
    needInfiniteScroll,
    allOptionsLength,
    optionsLength,
    fetchMoreResults,
  } = useOptions(props);

  const search = withSearch && (
    <Input
      ref={searchRef}
      placeholder="Search"
      onChange={(event) => filterResults(event.currentTarget.value)}
      css={dropdownSearch}
    />
  );

  const container = (
    <div role="presentation" onClick={(event) => event.stopPropagation()} css={className} ref={containerRef}>
      {search}
      {/* Wait for container to be mounted into the DOM
      and after that mount infinite scroll */}
      {containerRef.current.isConnected && needInfiniteScroll && (
        <InfiniteScroll
          dataLength={optionsLength}
          next={fetchMoreResults}
          hasMore={!!optionsLength && allOptionsLength > optionsLength}
          scrollableTarget={containerRef.current}>
          {renderList}
        </InfiniteScroll>
      )}
      {!needInfiniteScroll && renderList}
    </div>
  );

  return container;
};

OptionsList.propTypes = {
  options: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.bool]),
  displayKey: PropTypes.string,
  uniqueKey: PropTypes.string,
  onSelect: PropTypes.func,
  selected: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  theme: PropTypes.object,
  multiSelect: PropTypes.bool,
  withSearch: PropTypes.bool,
  isOpen: PropTypes.bool,
  containerRef: PropTypes.object,
};

export default OptionsList;

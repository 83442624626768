import { useTranslations } from '@veraio/strank';
import { Row, Col, NavProductBox } from 'components';
import { products } from 'enums';
import { header, body } from './styles';

const ProductsMore = () => {
  const { getText } = useTranslations();

  return (
    <>
      <section css={header}>
        <Row container>
          <Col sm={12}>
            <h2 data-strank-key="oneEcosystemProducts">{getText('oneEcosystemProducts')}</h2>
          </Col>
        </Row>
      </section>
      <div css={body}>
        <Row container horizontalGap={8} verticalGap={8} justify="center">
          {products.map((el) => (
            <Col sm={6} md={4} key={el?.id}>
              <NavProductBox data={el} largeText />
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
};

export default ProductsMore;
